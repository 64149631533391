import React, { useEffect, useState } from "react";
import axios from "axios";
import Sidebar from "../Sidebar/sidebar";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.css";
import { Circles } from "react-loader-spinner";
import { toast } from "react-toastify";
import { Button, Card, CardHeader, Modal } from "react-bootstrap";
import { Footer } from "../Footer/footer";
import { useNavigate, useLocation } from "react-router-dom";
import "./editWebsite.css";
function EditWebsite() {
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const location = useLocation();
  const [typesDataDropdown, setTypesDataDropdown] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const countryOptions = [
    { label: "Afghanistan", value: "Afghanistan" },
    { label: "Albania", value: "Albania" },
    { label: "Algeria", value: "Algeria" },
    { label: "Andorra", value: "Andorra" },
    { label: "Angola", value: "Angola" },
    { label: "Antigua and Barbuda", value: "Antigua and Barbuda" },
    { label: "Argentina", value: "Argentina" },
    { label: "Armenia", value: "Armenia" },
    { label: "Australia", value: "Australia" },
    { label: "Austria", value: "Austria" },
    { label: "Azerbaijan", value: "Azerbaijan" },
    { label: "Bahamas", value: "Bahamas" },
    { label: "Bahrain", value: "Bahrain" },
    { label: "Bangladesh", value: "Bangladesh" },
    { label: "Barbados", value: "Barbados" },
    { label: "Belarus", value: "Belarus" },
    { label: "Belgium", value: "Belgium" },
    { label: "Belize", value: "Belize" },
    { label: "Benin", value: "Benin" },
    { label: "Bhutan", value: "Bhutan" },
    { label: "Bolivia", value: "Bolivia" },
    { label: "Bosnia and Herzegovina", value: "Bosnia and Herzegovina" },
    { label: "Botswana", value: "Botswana" },
    { label: "Brazil", value: "Brazil" },
    { label: "Brunei", value: "Brunei" },
    { label: "Bulgaria", value: "Bulgaria" },
    { label: "Burkina Faso", value: "Burkina Faso" },
    { label: "Burundi", value: "Burundi" },
    { label: "Cabo Verde", value: "Cabo Verde" },
    { label: "Cambodia", value: "Cambodia" },
    { label: "Cameroon", value: "Cameroon" },
    { label: "Canada", value: "Canada" },
    { label: "Central African Republic", value: "Central African Republic" },
    { label: "Chad", value: "Chad" },
    { label: "Chile", value: "Chile" },
    { label: "China", value: "China" },
    { label: "Colombia", value: "Colombia" },
    { label: "Comoros", value: "Comoros" },
    { label: "Congo (Congo-Brazzaville)", value: "Congo (Congo-Brazzaville)" },
    { label: "Costa Rica", value: "Costa Rica" },
    { label: "Croatia", value: "Croatia" },
    { label: "Cuba", value: "Cuba" },
    { label: "Cyprus", value: "Cyprus" },
    { label: "Czechia (Czech Republic)", value: "Czechia (Czech Republic)" },
    {
      label: "Democratic Republic of the Congo",
      value: "Democratic Republic of the Congo",
    },
    { label: "Denmark", value: "Denmark" },
    { label: "Djibouti", value: "Djibouti" },
    { label: "Dominica", value: "Dominica" },
    { label: "Dominican Republic", value: "Dominican Republic" },
    { label: "Ecuador", value: "Ecuador" },
    { label: "Egypt", value: "Egypt" },
    { label: "El Salvador", value: "El Salvador" },
    { label: "Equatorial Guinea", value: "Equatorial Guinea" },
    { label: "Eritrea", value: "Eritrea" },
    { label: "Estonia", value: "Estonia" },
    {
      label: "Eswatini (fmr. 'Swaziland')",
      value: "Eswatini (fmr. 'Swaziland')",
    },
    { label: "Ethiopia", value: "Ethiopia" },
    { label: "Fiji", value: "Fiji" },
    { label: "Finland", value: "Finland" },
    { label: "France", value: "France" },
    { label: "Gabon", value: "Gabon" },
    { label: "Gambia", value: "Gambia" },
    { label: "Georgia", value: "Georgia" },
    { label: "Germany", value: "Germany" },
    { label: "Ghana", value: "Ghana" },
    { label: "Greece", value: "Greece" },
    { label: "Grenada", value: "Grenada" },
    { label: "Guatemala", value: "Guatemala" },
    { label: "Guinea", value: "Guinea" },
    { label: "Guinea-Bissau", value: "Guinea-Bissau" },
    { label: "Guyana", value: "Guyana" },
    { label: "Haiti", value: "Haiti" },
    { label: "Honduras", value: "Honduras" },
    { label: "Hungary", value: "Hungary" },
    { label: "Iceland", value: "Iceland" },
    { label: "India", value: "India" },
    { label: "Indonesia", value: "Indonesia" },
    { label: "Iran", value: "Iran" },
    { label: "Iraq", value: "Iraq" },
    { label: "Ireland", value: "Ireland" },
    { label: "Israel", value: "Israel" },
    { label: "Italy", value: "Italy" },
    { label: "Jamaica", value: "Jamaica" },
    { label: "Japan", value: "Japan" },
    { label: "Jordan", value: "Jordan" },
    { label: "Kazakhstan", value: "Kazakhstan" },
    { label: "Kenya", value: "Kenya" },
    { label: "Kiribati", value: "Kiribati" },
    { label: "Kosovo", value: "Kosovo" },
    { label: "Kuwait", value: "Kuwait" },
    { label: "Kyrgyzstan", value: "Kyrgyzstan" },
    { label: "Laos", value: "Laos" },
    { label: "Latvia", value: "Latvia" },
    { label: "Lebanon", value: "Lebanon" },
    { label: "Lesotho", value: "Lesotho" },
    { label: "Liberia", value: "Liberia" },
    { label: "Libya", value: "Libya" },
    { label: "Liechtenstein", value: "Liechtenstein" },
    { label: "Lithuania", value: "Lithuania" },
    { label: "Luxembourg", value: "Luxembourg" },
    { label: "Madagascar", value: "Madagascar" },
    { label: "Malawi", value: "Malawi" },
    { label: "Malaysia", value: "Malaysia" },
    { label: "Maldives", value: "Maldives" },
    { label: "Mali", value: "Mali" },
    { label: "Malta", value: "Malta" },
    { label: "Marshall Islands", value: "Marshall Islands" },
    { label: "Mauritania", value: "Mauritania" },
    { label: "Mauritius", value: "Mauritius" },
    { label: "Mexico", value: "Mexico" },
    { label: "Micronesia", value: "Micronesia" },
    { label: "Moldova", value: "Moldova" },
    { label: "Monaco", value: "Monaco" },
    { label: "Mongolia", value: "Mongolia" },
    { label: "Montenegro", value: "Montenegro" },
    { label: "Morocco", value: "Morocco" },
    { label: "Mozambique", value: "Mozambique" },
    { label: "Myanmar (formerly Burma)", value: "Myanmar (formerly Burma)" },
    { label: "Namibia", value: "Namibia" },
    { label: "Nauru", value: "Nauru" },
    { label: "Nepal", value: "Nepal" },
    { label: "Netherlands", value: "Netherlands" },
    { label: "New Zealand", value: "New Zealand" },
    { label: "Nicaragua", value: "Nicaragua" },
    { label: "Niger", value: "Niger" },
    { label: "Nigeria", value: "Nigeria" },
    { label: "North Korea", value: "North Korea" },
    { label: "North Macedonia", value: "North Macedonia" },
    { label: "Norway", value: "Norway" },
    { label: "Oman", value: "Oman" },
    { label: "Pakistan", value: "Pakistan" },
    { label: "Palau", value: "Palau" },
    { label: "Palestine State", value: "Palestine State" },
    { label: "Panama", value: "Panama" },
    { label: "Papua New Guinea", value: "Papua New Guinea" },
    { label: "Paraguay", value: "Paraguay" },
    { label: "Peru", value: "Peru" },
    { label: "Philippines", value: "Philippines" },
    { label: "Poland", value: "Poland" },
    { label: "Portugal", value: "Portugal" },
    { label: "Qatar", value: "Qatar" },
    { label: "Romania", value: "Romania" },
    { label: "Russia", value: "Russia" },
    { label: "Rwanda", value: "Rwanda" },
    { label: "Saint Kitts and Nevis", value: "Saint Kitts and Nevis" },
    { label: "Saint Lucia", value: "Saint Lucia" },
    {
      label: "Saint Vincent and the Grenadines",
      value: "Saint Vincent and the Grenadines",
    },
    { label: "Samoa", value: "Samoa" },
    { label: "San Marino", value: "San Marino" },
    { label: "Sao Tome and Principe", value: "Sao Tome and Principe" },
    { label: "Saudi Arabia", value: "Saudi Arabia" },
    { label: "Senegal", value: "Senegal" },
    { label: "Serbia", value: "Serbia" },
    { label: "Seychelles", value: "Seychelles" },
    { label: "Sierra Leone", value: "Sierra Leone" },
    { label: "Singapore", value: "Singapore" },
    { label: "Slovakia", value: "Slovakia" },
    { label: "Slovenia", value: "Slovenia" },
    { label: "Solomon Islands", value: "Solomon Islands" },
    { label: "Somalia", value: "Somalia" },
    { label: "South Africa", value: "South Africa" },
    { label: "South Korea", value: "South Korea" },
    { label: "South Sudan", value: "South Sudan" },
    { label: "Spain", value: "Spain" },
    { label: "Sri Lanka", value: "Sri Lanka" },
    { label: "Sudan", value: "Sudan" },
    { label: "Suriname", value: "Suriname" },
    { label: "Sweden", value: "Sweden" },
    { label: "Switzerland", value: "Switzerland" },
    { label: "Syria", value: "Syria" },
    { label: "Tajikistan", value: "Tajikistan" },
    { label: "Tanzania", value: "Tanzania" },
    { label: "Thailand", value: "Thailand" },
    { label: "Timor-Leste", value: "Timor-Leste" },
    { label: "Togo", value: "Togo" },
    { label: "Tonga", value: "Tonga" },
    { label: "Trinidad and Tobago", value: "Trinidad and Tobago" },
    { label: "Tunisia", value: "Tunisia" },
    { label: "Turkey", value: "Turkey" },
    { label: "Turkmenistan", value: "Turkmenistan" },
    { label: "Tuvalu", value: "Tuvalu" },
    { label: "Uganda", value: "Uganda" },
    { label: "Ukraine", value: "Ukraine" },
    { label: "United Arab Emirates", value: "United Arab Emirates" },
    { label: "United Kingdom", value: "United Kingdom" },
    { label: "USA", value: "USA" },
    { label: "Uruguay", value: "Uruguay" },
    { label: "Uzbekistan", value: "Uzbekistan" },
    { label: "Vanuatu", value: "Vanuatu" },
    { label: "Vatican City", value: "Vatican City" },
    { label: "Venezuela", value: "Venezuela" },
    { label: "Vietnam", value: "Vietnam" },
    { label: "Yemen", value: "Yemen" },
    { label: "Zambia", value: "Zambia" },
    { label: "Zimbabwe", value: "Zimbabwe" },
  ];

  const languageOptions = [
    { label: "Afar", value: "Afar" },
    { label: "Abkhazian", value: "Abkhazian" },
    { label: "Avestan", value: "Avestan" },
    { label: "Afrikaans", value: "Afrikaans" },
    { label: "Akan", value: "Akan" },
    { label: "Amharic", value: "Amharic" },
    { label: "Aragonese", value: "Aragonese" },
    { label: "Arabic", value: "Arabic" },
    { label: "Assamese", value: "Assamese" },
    { label: "Avaric", value: "Avaric" },
    { label: "Aymara", value: "Aymara" },
    { label: "Azerbaijani", value: "Azerbaijani" },
    { label: "Bambara", value: "Bambara" },
    { label: "Bashkir", value: "Bashkir" },
    { label: "Basque", value: "Basque" },
    { label: "Belarusian", value: "Belarusian" },
    { label: "Bengali", value: "Bengali" },
    { label: "Bihari", value: "Bihari" },
    { label: "Bislama", value: "Bislama" },
    { label: "Bosnian", value: "Bosnian" },
    { label: "Breton", value: "Breton" },
    { label: "Bulgarian", value: "Bulgarian" },
    { label: "Burmese", value: "Burmese" },
    { label: "Catalan", value: "Catalan" },
    { label: "Chamorro", value: "Chamorro" },
    { label: "Chechen", value: "Chechen" },
    { label: "Chichewa", value: "Chichewa" },
    { label: "Chinese", value: "Chinese" },
    { label: "Chuvash", value: "Chuvash" },
    { label: "Cornish", value: "Cornish" },
    { label: "Corsican", value: "Corsican" },
    { label: "Cree", value: "Cree" },
    { label: "Croatian", value: "Croatian" },
    { label: "Czech", value: "Czech" },
    { label: "Danish", value: "Danish" },
    { label: "Divehi", value: "Divehi" },
    { label: "Dutch", value: "Dutch" },
    { label: "Dzongkha", value: "Dzongkha" },
    { label: "English", value: "English" },
    { label: "Esperanto", value: "Esperanto" },
    { label: "Estonian", value: "Estonian" },
    { label: "Ewe", value: "Ewe" },
    { label: "Faroese", value: "Faroese" },
    { label: "Fijian", value: "Fijian" },
    { label: "Finnish", value: "Finnish" },
    { label: "French", value: "French" },
    { label: "Fulah", value: "Fulah" },
    { label: "Gaelic", value: "Gaelic" },
    { label: "Galician", value: "Galician" },
    { label: "Ganda", value: "Ganda" },
    { label: "Georgian", value: "Georgian" },
    { label: "German", value: "German" },
    { label: "Greek", value: "Greek" },
    { label: "Guarani", value: "Guarani" },
    { label: "Gujarati", value: "Gujarati" },
    { label: "Haitian", value: "Haitian" },
    { label: "Hausa", value: "Hausa" },
    { label: "Hebrew", value: "Hebrew" },
    { label: "Herero", value: "Herero" },
    { label: "Hindi", value: "Hindi" },
    { label: "Hiri Motu", value: "Hiri Motu" },
    { label: "Hungarian", value: "Hungarian" },
    { label: "Icelandic", value: "Icelandic" },
    { label: "Ido", value: "Ido" },
    { label: "Igbo", value: "Igbo" },
    { label: "Indonesian", value: "Indonesian" },
    { label: "Interlingua", value: "Interlingua" },
    { label: "Interlingue", value: "Interlingue" },
    { label: "Inuktitut", value: "Inuktitut" },
    { label: "Inupiaq", value: "Inupiaq" },
    { label: "Irish", value: "Irish" },
    { label: "Italian", value: "Italian" },
    { label: "Japanese", value: "Japanese" },
    { label: "Javanese", value: "Javanese" },
    { label: "Kalaallisut", value: "Kalaallisut" },
    { label: "Kannada", value: "Kannada" },
    { label: "Kanuri", value: "Kanuri" },
    { label: "Kashmiri", value: "Kashmiri" },
    { label: "Kazakh", value: "Kazakh" },
    { label: "Khmer", value: "Khmer" },
    { label: "Kikuyu", value: "Kikuyu" },
    { label: "Kinyarwanda", value: "Kinyarwanda" },
    { label: "Kirghiz", value: "Kirghiz" },
    { label: "Komi", value: "Komi" },
    { label: "Kongo", value: "Kongo" },
    { label: "Korean", value: "Korean" },
    { label: "Kuanyama", value: "Kuanyama" },
    { label: "Kurdish", value: "Kurdish" },
    { label: "Kwanyama", value: "Kwanyama" },
    { label: "Lao", value: "Lao" },
    { label: "Latin", value: "Latin" },
    { label: "Latvian", value: "Latvian" },
    { label: "Limburgish", value: "Limburgish" },
    { label: "Lingala", value: "Lingala" },
    { label: "Lithuanian", value: "Lithuanian" },
    { label: "Luba-Katanga", value: "Luba-Katanga" },
    { label: "Luxembourgish", value: "Luxembourgish" },
    { label: "Macedonian", value: "Macedonian" },
    { label: "Malagasy", value: "Malagasy" },
    { label: "Malay", value: "Malay" },
    { label: "Malayalam", value: "Malayalam" },
    { label: "Maltese", value: "Maltese" },
    { label: "Manx", value: "Manx" },
    { label: "Maori", value: "Maori" },
    { label: "Marathi", value: "Marathi" },
    { label: "Marshallese", value: "Marshallese" },
    { label: "Mongolian", value: "Mongolian" },
    { label: "Nauru", value: "Nauru" },
    { label: "Navajo", value: "Navajo" },
    { label: "Ndonga", value: "Ndonga" },
    { label: "Nepali", value: "Nepali" },
    { label: "North Ndebele", value: "North Ndebele" },
    { label: "Northern Sami", value: "Northern Sami" },
    { label: "Norwegian", value: "Norwegian" },
    { label: "Norwegian Bokmål", value: "Norwegian Bokmål" },
    { label: "Norwegian Nynorsk", value: "Norwegian Nynorsk" },
    { label: "Nuosu", value: "Nuosu" },
    { label: "Occitan", value: "Occitan" },
    { label: "Ojibwa", value: "Ojibwa" },
    { label: "Oriya", value: "Oriya" },
    { label: "Oromo", value: "Oromo" },
    { label: "Ossetian", value: "Ossetian" },
    { label: "Pali", value: "Pali" },
    { label: "Pashto", value: "Pashto" },
    { label: "Persian", value: "Persian" },
    { label: "Polish", value: "Polish" },
    { label: "Portuguese", value: "Portuguese" },
    { label: "Punjabi", value: "Punjabi" },
    { label: "Quechua", value: "Quechua" },
    { label: "Romanian", value: "Romanian" },
    { label: "Romansh", value: "Romansh" },
    { label: "Rundi", value: "Rundi" },
    { label: "Russian", value: "Russian" },
    { label: "Samoan", value: "Samoan" },
    { label: "Sango", value: "Sango" },
    { label: "Sanskrit", value: "Sanskrit" },
    { label: "Sardinian", value: "Sardinian" },
    { label: "Scottish Gaelic", value: "Scottish Gaelic" },
    { label: "Serbian", value: "Serbian" },
    { label: "Shona", value: "Shona" },
    { label: "Sindhi", value: "Sindhi" },
    { label: "Sinhala", value: "Sinhala" },
    { label: "Slovak", value: "Slovak" },
    { label: "Slovenian", value: "Slovenian" },
    { label: "Somali", value: "Somali" },
    { label: "South Ndebele", value: "South Ndebele" },
    { label: "Southern Sotho", value: "Southern Sotho" },
    { label: "Spanish", value: "Spanish" },
    { label: "Sundanese", value: "Sundanese" },
    { label: "Swahili", value: "Swahili" },
    { label: "Swati", value: "Swati" },
    { label: "Swedish", value: "Swedish" },
    { label: "Tagalog", value: "Tagalog" },
    { label: "Tahitian", value: "Tahitian" },
    { label: "Tajik", value: "Tajik" },
    { label: "Tamil", value: "Tamil" },
    { label: "Tatar", value: "Tatar" },
    { label: "Telugu", value: "Telugu" },
    { label: "Thai", value: "Thai" },
    { label: "Tibetan", value: "Tibetan" },
    { label: "Tigrinya", value: "Tigrinya" },
    { label: "Tonga", value: "Tonga" },
    { label: "Tsonga", value: "Tsonga" },
    { label: "Tswana", value: "Tswana" },
    { label: "Turkish", value: "Turkish" },
    { label: "Turkmen", value: "Turkmen" },
    { label: "Twi", value: "Twi" },
    { label: "Uighur", value: "Uighur" },
    { label: "Ukrainian", value: "Ukrainian" },
    { label: "Urdu", value: "Urdu" },
    { label: "Uzbek", value: "Uzbek" },
    { label: "Venda", value: "Venda" },
    { label: "Vietnamese", value: "Vietnamese" },
    { label: "Volapük", value: "Volapük" },
    { label: "Walloon", value: "Walloon" },
    { label: "Welsh", value: "Welsh" },
    { label: "Western Frisian", value: "Western Frisian" },
    { label: "Wolof", value: "Wolof" },
    { label: "Xhosa", value: "Xhosa" },
    { label: "Yiddish", value: "Yiddish" },
    { label: "Yoruba", value: "Yoruba" },
    { label: "Zhuang", value: "Zhuang" },
    { label: "Zulu", value: "Zulu" }
  ];
  
  const userData = location.state.userData;
  const typeSelected = userData?.websites[0].types?.map((item) => ({
    value: item?._id,
    label: item?.TypeName,
  }));

  const categorySelected = userData?.websites[0].categories?.map((item) => ({
    value: item?._id,
    label: item?.categoryName,
  }));
  const handleShowModal = () => {
    setShowCategoryModal(true);
  };
  const handleCloseModal = () => {
    setShowCategoryModal(false);
  };
  const navigate = useNavigate();
  const [name, setName] = useState("");
  console.log(userData, "USER");
  const [editDataModal, setEditDataModal] = useState({
    id: userData?.websites[0]._id,
    url: userData?.websites[0].url,
    contactEmail: userData?.websites[0].contactEmail,
    emailUsed: userData?.websites[0].emailUsed,
    teamMember: {
      value: userData?.websites[0].teamMember[0]?._id,
      label: userData?.websites[0].teamMember[0]?.name,
    },
    domainRating: userData?.domainRating,
    domainAuthority: userData?.domainAuthority,
    spamScore: userData?.spamScore,
    priceGP: userData?.priceGP,
    priceLI: userData?.priceLI,
    authorityScore: userData?.authorityScore,
    linkStatus: { value: userData?.websites[0].linkStatus, label: userData?.websites[0].linkStatus },
    ahrefs: userData?.ahrefs,
    semrush: userData?.semrush,
    type: typeSelected,
    country: { value: userData?.websites[0].country, label: userData?.websites[0].country },
    language: { value: userData?.websites[0].language, label: userData?.websites[0].language },
    slackCommunity: userData?.websites[0].slackCommunity,
    currencyGP: userData?.websites[0].currencyGP,
    category: categorySelected,
  });
  console.log(editDataModal, "user 233");
  const [addCategoryModal, setAddCategoryModal] = useState(null);
  const [categoriesDropdownData, setCategoriesDropdownData] = useState([]);
  const [teamMemberDropdownData, setTeamMemberDropdownData] = useState([]);
  const [linkStatusOptions, setlinkStatusOptions ]=useState([])
  const categoriesDropdownOptions = [];
  categoriesDropdownData.map((type) =>
    categoriesDropdownOptions.push({
      value: type._id,
      label: type.categoryName,
    })
  );

  const typesDropdownOptions = [];
  typesDataDropdown.map((type) =>
    typesDropdownOptions.push({ value: type._id, label: type.TypeName })
  );
  useEffect(() => {}, []);

  useEffect(() => {
    listTypesDropdown();
    listCategoriesDropdown();
    listTeamMemberDropdown();
    listStatusDropdown();
    listCategories();
  }, []);
  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  
  const listStatusDropdown = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_API}/api/link/list-all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setlinkStatusOptions(res.data.result.map((linkStatus)=>(
        {
          value:linkStatus._id,
          label:linkStatus.linkStatus
        }  
        )))
      })
      .catch((err) => toast.error(err.response.data.message));
  };
  const listTypesDropdown = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_API}/api/type/list-all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setTypesDataDropdown(res.data.result);
      })
      .catch((err) => toast.error(err.response.data.message));
  };
  const listCategories = () => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER_API}/api/category/list?pageNo=${1}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setCategoriesData(res.data.result.data);
      })
      .catch((err) => toast.error(err.response.data.message));
  };

  const listTeamMemberDropdown = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_API}/api/user/list-all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setTeamMemberDropdownData(res.data.result);
      })
      .catch((err) => toast.error(err.response.data.message));
  };
  const listCategoriesDropdown = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_API}/api/category/list-all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setCategoriesDropdownData(res.data.result);
        res?.data?.result.forEach((element) => {
          if (element.categoryName === name) {
            let tempArr = editDataModal.category;
            tempArr.push({ value: element?._id, label: element?.categoryName });
            setEditDataModal({ ...editDataModal, category: tempArr });
          }
        });
        setName("");
      })
      .catch((err) => toast.error(err.response.data.message));
  };

  const handleUpdateSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const linkStatus = editDataModal.linkStatus;
    const formData = {
      ...editDataModal,
      linkStatus: linkStatus.value,
      country: editDataModal.country.value,
      language: editDataModal.language.value,
      category: editDataModal.category,
      type: editDataModal.type,
      teamMember: editDataModal.teamMember.value,
    };

    axios
      .post(`${process.env.REACT_APP_SERVER_API}/api/web/update`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setData([...data, res.data]);
        toast.success("Website Updated Successfully");

        setLoading(false);

        // Reset the updateDataModal state to initial values
        setEditDataModal({
          url: "",
          contactEmail: "",
          emailUsed: "",
          teamMember: {},
          domainRating: 0,
          domainAuthority: 0,
          spamScore: 0,
          priceGP: 0,
          priceLI: 0,
          authorityScore: 0,
          linkStatus: { value: "Request Status", label: "Request Status" },
          ahrefs: 0,
          semrush: 0,
          type: [],
          country: "",
          language: "",
          slackCommunity: "",
          currencyGP: "",
        });
        handleBack();
      })
      .catch((err) => {
        if (err.response && err.response.status === 400) {
          // Handle 400 Bad Request
          toast.error(err.response.data.message);
        } else {
          // Handle other errors
          toast.error(err.message);
        }
        setLoading(false);
      });
  };
  const handleAddCategory = (e) => {
    e.preventDefault();
    setLoading(true);

    const parentValue = selectedOption ? selectedOption.value : null;
    console.log(selectedOption, "exiswww", selectedOption);

    axios
      .post(
        `${process.env.REACT_APP_SERVER_API}/api/category/add`,
        {
          categoryName: name,
          parent: parentValue,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        // setData([...data, res.data]);

        toast.success("Category Added Succesfully");
        handleCloseModal();
        setLoading(false);
        setSelectedOption({});
        listCategoriesDropdown();
      })
      .catch((err) => toast.error(err.response.data.message))
      .finally(() => setLoading(false));
  };

  const teamMemberDropdownOptions = [];
  teamMemberDropdownData.map((type) =>
    teamMemberDropdownOptions.push({ value: type._id, label: type.name })
  );
  const categoriesOptions = [];
  categoriesData.map((type) =>
    categoriesOptions.push({
      value: type.parent._id,
      label: type.parent.categoryName,
    })
  );

  const handleBack = () => {
    navigate("/websites");
  };
  const handleEditModalChange = (event) => {
    const { name, value } = event.target;
    console.log(editDataModal, name, value, "maaz22");
    setEditDataModal((prevEditDataModal) => ({
      ...prevEditDataModal,
      [name]: value,
    }));
  };
  const handleEditSelectChanges = (fieldName, selectedOption) => {
    setEditDataModal((prevEditDataModal) => ({
      ...prevEditDataModal,
      [fieldName]: selectedOption,
    }));
  };
  const handleCategoryDropdownChange = (fieldName, selectedOption) => {
    if (Array.isArray(selectedOption)) {
      const exists = selectedOption.some(
        (obj) => obj.label === "+ Add New Category"
      );

      if (exists) {
        handleShowModal();
        setEditDataModal((prevEditDataModal) => ({
          ...prevEditDataModal,
        }));
      } else {
        console.log("exist not");
        setEditDataModal((prevEditDataModal) => ({
          ...prevEditDataModal,
          [fieldName]: selectedOption,
        }));
      }
    } else {
      console.log("exist not");
      setEditDataModal((prevEditDataModal) => ({
        ...prevEditDataModal,
        [fieldName]: selectedOption,
      }));
    }
  };

  return (
    <div className="d-flex">
      <Sidebar />
      <div
        className="container-fluid "
        style={{ height: "100vh", overflow: "auto" }}
      >
        <div className="row m-0">
          <Circles
            height="80"
            width="80"
            color="#3155a7"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass="loading-spinner-overlay"
            visible={loading}
          />

          <>
            <form onSubmit={handleUpdateSubmit}>
              <div className="mt-3">
                <h3>Update Website</h3>
              </div>
              <Card className="mt-2" style={{ marginBottom: "70px" }}>
                <CardHeader></CardHeader>
                <div className="d-flex justify-content-end my-3">
                  <div className="row m-0">
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <label htmlFor="URL" className="form-label">
                        URL *
                      </label>
                      <input
                        type="url"
                        className="form-control"
                        id="URL"
                        name="url"
                        value={editDataModal?.url}
                        pattern="^https?:\/\/\S+$"
                        placeholder="Please enter a valid URL with http or https://"
                        title="Please enter a valid URL with http or https://"
                        onChange={(event) => handleEditModalChange(event)}
                        required
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <label htmlFor="email" className="form-label">
                        Contact Email *
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="contactEmail"
                        value={editDataModal?.contactEmail}
                        onChange={(event) => handleEditModalChange(event)}
                        required
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <label htmlFor="email" className="form-label">
                        Email Used *
                      </label>
                      <input
                        type="emailUsed"
                        className="form-control"
                        id="emailUsed"
                        name="emailUsed"
                        value={editDataModal?.emailUsed}
                        onChange={(event) => handleEditModalChange(event)}
                        required
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <label htmlFor="Categories" className="form-label">
                        Team Member *
                      </label>

                      <Select
                        required
                        options={teamMemberDropdownOptions}
                        value={editDataModal?.teamMember}
                        onChange={(selectedOptions) =>
                          handleEditSelectChanges("teamMember", selectedOptions)
                        }
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <label htmlFor="domainRating" className="form-label">
                        Domain Rating *
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="domainRating"
                        min="0"
                        max="100"
                        step="1"
                        name="domainRating"
                        value={editDataModal?.domainRating}
                        onChange={(event) => handleEditModalChange(event)}
                        required
                      />
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <label htmlFor="domainRating" className="form-label">
                        Domain Authority *
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="domainAuthority"
                        min="0"
                        max="100"
                        step="1"
                        name="domainAuthority"
                        value={editDataModal?.domainAuthority}
                        onChange={(event) => handleEditModalChange(event)}
                        required
                      />
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <label htmlFor="price" className="form-label">
                        GP Price
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        min="0"
                        step="1"
                        id="price"
                        name="priceGP"
                        value={editDataModal?.priceGP}
                        onChange={(event) => handleEditModalChange(event)}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <label htmlFor="Categories" className="form-label">
                        Currency
                      </label>

                      <input
                        type="text"
                        className="form-control"
                        id="currencyGP"
                        name="currencyGP"
                        value={editDataModal?.currencyGP}
                        onChange={(event) => handleEditModalChange(event)}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <label htmlFor="price" className="form-label">
                        LI Price
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        min="0"
                        step="1"
                        id="price"
                        name="priceLI"
                        value={editDataModal?.priceLI}
                        onChange={(event) => handleEditModalChange(event)}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <label htmlFor="authorityScore" className="form-label">
                        Authority Score *
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="authorityScore"
                        name="authorityScore"
                        min="0"
                        max="100"
                        step="1"
                        value={editDataModal?.authorityScore}
                        onChange={(event) => handleEditModalChange(event)}
                        required
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <label htmlFor="spamScore" className="form-label">
                        Spam Score *
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        min="0"
                        name="spamScore"
                        step="1"
                        id="price"
                        value={editDataModal?.spamScore}
                        onChange={(event) => handleEditModalChange(event)}
                        required
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <label htmlFor="domainRating" className="form-label">
                        Ahrefs Traffic *
                      </label>
                      <input
                        type="number"
                        min="0"
                        step="1"
                        className="form-control"
                        id="Hrefs"
                        name="ahrefs"
                        value={editDataModal?.ahrefs}
                        onChange={(event) => handleEditModalChange(event)}
                        required
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <label htmlFor="domainRating" className="form-label">
                        Semrush Traffic *
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="Semrush"
                        value={editDataModal?.semrush}
                        name="semrush"
                        onChange={(event) => handleEditModalChange(event)}
                        required
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <label htmlFor="Types" className="form-label">
                        Types *
                      </label>

                      <Select
                        required
                        isMulti
                        options={typesDropdownOptions}
                        value={editDataModal?.type}
                        onChange={(selectedOptions) =>
                          handleEditSelectChanges("type", selectedOptions)
                        }
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <label htmlFor="linkStatus" className="form-label">
                        Link Status *
                      </label>
                      <Select
                        required
                        options={linkStatusOptions}
                        value={editDataModal?.linkStatus}
                        onChange={(selectedOptions) =>
                          handleEditSelectChanges("linkStatus", selectedOptions)
                        }
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <label htmlFor="Categories" className="form-label">
                        Categories *
                      </label>

                      <Select
                        required
                        isMulti
                        options={[
                          {
                            value: "static_option",
                            label: "+ Add New Category",
                          },
                          ...categoriesDropdownOptions,
                        ]}
                        value={editDataModal?.category}
                        onChange={(selectedOptions) =>
                          handleCategoryDropdownChange(
                            "category",
                            selectedOptions
                          )
                        }
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <label htmlFor="Categories" className="form-label">
                        Country *
                      </label>
                      <Select
                        required
                        options={countryOptions}
                        name="catFilter"
                        value={editDataModal?.country}
                        onChange={(selectedOptions) =>
                          handleEditSelectChanges("country", selectedOptions)
                        }
                        placeholder="Country"
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <label htmlFor="Categories" className="form-label">
                        Language *
                      </label>
                      <Select
                        required
                        options={languageOptions}
                        name="language"
                        value={editDataModal?.language}
                        onChange={(selectedOptions) =>
                          handleEditSelectChanges("language", selectedOptions)
                        }
                        placeholder="Language"
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <label htmlFor="Categories" className="form-label">
                        Slack Community *
                      </label>

                      <input
                        type="text"
                        className="form-control"
                        id="slackCommunity"
                        name="slackCommunity"
                        value={editDataModal?.slackCommunity}
                        onChange={(event) => handleEditModalChange(event)}
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-end my-3">
                  <button
                    type="button"
                    onClick={handleBack}
                    className="btn btn-secondary"
                  >
                    Back
                  </button>
                  <button type="submit" className="mx-2 btn btn-primary">
                    Update Website
                  </button>
                </div>
              </Card>
            </form>
            <Modal show={showCategoryModal} onHide={handleCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>Add Category</Modal.Title>
              </Modal.Header>
              <form onSubmit={handleAddCategory}>
                <Modal.Body>
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Name *
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                    <label htmlFor="name" className="form-label mt-2">
                      Parent
                    </label>
                    <Select
                      options={categoriesOptions}
                      name="typeFilter"
                      value={selectedOption}
                      onChange={handleChange}
                      placeholder="Categories"
                    />
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    onClick={handleCloseModal}
                    type="button"
                    className="btn btn-secondary"
                  >
                    Close
                  </Button>
                  <Button type="submit" className="btn btn-primary">
                    Add Category
                  </Button>
                </Modal.Footer>
              </form>
            </Modal>
          </>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default EditWebsite;
