import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { BsPencilSquare, BsTrash, BsPlus, BsEye } from "react-icons/bs";
import Sidebar from "../Sidebar/sidebar";
import Pagination from "../Pagination";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { Circles } from "react-loader-spinner";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
} from "react-bootstrap";
import "./websites.css";
import {
  MdClose,
  MdOutlineArrowDropDown,
  MdOutlineArrowDropUp,
} from "react-icons/md";
import { Footer } from "../Footer/footer";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { FaArrowDown } from "react-icons/fa";

function Websites() {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showDownloadModal, setDownloadModal] = useState(false);
  const handleCloseModal = () => setShowDeleteModal(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedWebsite, setSelectedWebsite] = useState(null);
  const handleShowModal = () => setShowDeleteModal(true);
  const handleDownloadCloseModal = () => setDownloadModal(false);
  const handleDownloadShowModal = () => {
    setDownloadModal(true);
    setFromValue(1);
    setToValue(paginationValue.total);
  };

  const [data, setData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);

  //filters
  const [domainRatingStart, setDomainRatingStart] = useState("");
  const [domainRatingEnd, setDomainRatingEnd] = useState("");
  const [domainAuthorityStart, setDomainAuthorityStart] = useState("");
  const [domainAuthorityEnd, setDomainAuthorityEnd] = useState("");
  const [spamScoreStart, setSpamScoreStart] = useState("");
  const [spamScoreEnd, setSpamScoreEnd] = useState("");
  const [priceStart, setPriceStart] = useState("");
  const [priceEnd, setPriceEnd] = useState("");
  const [authorityScoreStart, setauthorityScoreStart] = useState("");
  const [authorityScoreEnd, setAuthorityScoreEnd] = useState("");
  const [ahrefsStart, setAhrefsStart] = useState("");
  const [ahrefsEnd, setAhrefsEnd] = useState("");
  const [semrushStart, setSemrushStart] = useState("");
  const [semrushEnd, setSemrushEnd] = useState("");
  const [linkStatusOptions, setlinkStatusOptions] = useState([]);

  const [typesData, setTypesData] = useState([]);

  //modal
  const [categoriesData, setCategoriesData] = useState([]);
  const [categoriesDropdownData, setCategoriesDropdownData] = useState([]);
  const [teamMemberDropdownData, setTeamMemberDropdownData] = useState([]);

  //filter or modal
  const [ahrefs, setHrefs] = useState("");
  const [semrush, setSemrush] = useState("");
  const [teamMember, setTeamMember] = useState("");
  const [entries, setEntries] = useState(10);
  const [linkStatusSelectedOptions, setLinkStatusSelectedOptions] = useState({
    label: "",
    value: "",
  });

  //used
  const [typesDataDropdown, setTypesDataDropdown] = useState([]);

  const [selectedFilterCountryOptions, setSelectedFilterCountryOptions] =
    useState({});
  const [
    selectedFilterCateogryTypeOptions,
    setSelectedFilterCategoryTypeOptions,
  ] = useState([]);
  const [selectedFilterCategoriesOptions, setSelectedFilterCategoriesOptions] =
    useState([]);
  const [selectedFilterTypesOptions, setSelectedFilterTypesOptions] = useState(
    {}
  );
  const [selectedFilterTeamMemberOptions, setSelectedFilterTeamMemberOptions] =
    useState({});
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");

  const [paginationValue, setPaginationValue] = useState({});
  const [typeValue, setTypeValue] = useState("");
  const [teamMemberValue, setTeamMemberValue] = useState("");
  const [languageValue, setLanguageValue] = useState("");
  const [categoryValue, setCategoryValue] = useState([]);
  const [catValue, setCatValue] = useState([]);
  const [countryValue, setCountryValue] = useState("");
  const [resetLoading, setResetLoading] = useState(false);
  const [from, setFromValue] = useState(1);
  const [to, setToValue] = useState(1);
  const [expandedRow, setExpandedRow] = useState(null);

  // Filters Popup
  const [dropdownOpenDr, setDropdownOpenDr] = useState(false);
  const [dropdownOpenDa, setDropdownOpenDa] = useState(false);
  const [dropdownOpenSS, setDropdownOpenSS] = useState(false);
  const [dropdownOpenPrice, setDropdownOpenPrice] = useState(false);
  const [dropdownOpenAS, setDropdownOpenAS] = useState(false);
  const [dropdownOpenahrefs, setDropdownOpenahrefs] = useState(false);
  const [dropdownOpenSemrush, setDropdownOpenSemrush] = useState(false);
  const [dropdownOpenTeamMember, setDropdownOpenTeamMember] = useState(false);

  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");

  const permission = localStorage.getItem("permission");
  const userId = localStorage.getItem("userId");

  const [selectedRows, setSelectedRows] = useState([]);
  const handleSelectAll = () => {
    if (selectedRows.length === data.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(data.map((user) => user.websites[0]._id));
    }
  };

  const handleSelectRow = (id) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };

  const webCheck = (url) => {
    if (url.startsWith("https://")) {
      return url;
    } else {
      return "https://" + url;
    }
  };

  
  const listStatusDropdown = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_API}/api/link/list-all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setlinkStatusOptions(res.data.result.map((linkStatus)=>(
        {
          value:linkStatus._id,
          label:linkStatus.linkStatus
        }  
        )))
      })
      .catch((err) => toast.error(err.response.data.message));
  };

  const checkPermissions = (permissionName) => {
    return permission === permissionName;
  };

  const dropdownRef = useRef(null);

  const incrementToNextStep = (number) => {
    // Convert number to an integer
    let integerPart = parseInt(number);
    // Add 1 to the integer part
    if(number%1!==0){
      integerPart++;
    }
    // If there's a decimal part, round it up
    let decimalPart = number - integerPart;
    
    if (decimalPart > 0) {
      integerPart++;
    }
    return integerPart;
  };

  const handleEyeModal = (websiteId) => {
    const website = data.find((user) => user.websites[0]._id === websiteId);
    setSelectedWebsite(website);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const uploadedBy = checkPermissions("Editor") ? userId : "";

  const handleDropdown = (name) => {
    setDropdownOpenDr(name == "dr" ? !dropdownOpenDr : false);
    setDropdownOpenDa(name == "da" ? !dropdownOpenDa : false);
    setDropdownOpenSS(name == "ss" ? !dropdownOpenSS : false);
    setDropdownOpenPrice(name == "p" ? !dropdownOpenPrice : false);
    setDropdownOpenAS(name == "as" ? !dropdownOpenAS : false);
    setDropdownOpenahrefs(name == "hr" ? !dropdownOpenahrefs : false);
    setDropdownOpenSemrush(name == "se" ? !dropdownOpenSemrush : false);
    setDropdownOpenTeamMember(name == "tm" ? !dropdownOpenTeamMember : false);
  };

  const handleDropdownClick = (event) => {
    event.stopPropagation();
  };

  const toggleRowExpansion = (rowId) => {
    setExpandedRow((prevRowId) => (prevRowId === rowId ? null : rowId));
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !event.target.closest(".dropdown-content")
    ) {
      setDropdownOpenSS(false);
      setDropdownOpenDr(false);
      setDropdownOpenDa(false);
      setDropdownOpenPrice(false);
      setDropdownOpenAS(false);
      setDropdownOpenahrefs(false);
      setDropdownOpenSemrush(false);
    }
  };

  function removeHttp(url) {
    if (url === null) return url;
    // Remove "http://" or "https://" if present, and also remove "www."
    let cleanUrl = url.replace(/^(https?:\/\/)?(www\.)?/i, "");

    return cleanUrl;
  }

  const handleEntriesChange = (e) => {
    const newValue = parseInt(e.target.value);
    setEntries(newValue);
    setCurrentPage(1);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const fileInputRefProfile = useRef("");
  // const linkStatusOptions = [
  //   { label: "Request Sent", value: "Request Sent" },
  //   { label: "Webmaster Request Pending", value: "Webmaster Request Pending" },
  //   { label: "Available", value: "Available" },
  //   { label: "In dealing", value: "In dealing" },
  //   { label: "In dealing for next link", value: "In dealing for next link" },
  // ];

  const countryOptions = [
    { label: "Afghanistan", value: "Afghanistan" },
    { label: "Albania", value: "Albania" },
    { label: "Algeria", value: "Algeria" },
    { label: "Andorra", value: "Andorra" },
    { label: "Angola", value: "Angola" },
    { label: "Antigua and Barbuda", value: "Antigua and Barbuda" },
    { label: "Argentina", value: "Argentina" },
    { label: "Armenia", value: "Armenia" },
    { label: "Australia", value: "Australia" },
    { label: "Austria", value: "Austria" },
    { label: "Azerbaijan", value: "Azerbaijan" },
    { label: "Bahamas", value: "Bahamas" },
    { label: "Bahrain", value: "Bahrain" },
    { label: "Bangladesh", value: "Bangladesh" },
    { label: "Barbados", value: "Barbados" },
    { label: "Belarus", value: "Belarus" },
    { label: "Belgium", value: "Belgium" },
    { label: "Belize", value: "Belize" },
    { label: "Benin", value: "Benin" },
    { label: "Bhutan", value: "Bhutan" },
    { label: "Bolivia", value: "Bolivia" },
    { label: "Bosnia and Herzegovina", value: "Bosnia and Herzegovina" },
    { label: "Botswana", value: "Botswana" },
    { label: "Brazil", value: "Brazil" },
    { label: "Brunei", value: "Brunei" },
    { label: "Bulgaria", value: "Bulgaria" },
    { label: "Burkina Faso", value: "Burkina Faso" },
    { label: "Burundi", value: "Burundi" },
    { label: "Cabo Verde", value: "Cabo Verde" },
    { label: "Cambodia", value: "Cambodia" },
    { label: "Cameroon", value: "Cameroon" },
    { label: "Canada", value: "Canada" },
    { label: "Central African Republic", value: "Central African Republic" },
    { label: "Chad", value: "Chad" },
    { label: "Chile", value: "Chile" },
    { label: "China", value: "China" },
    { label: "Colombia", value: "Colombia" },
    { label: "Comoros", value: "Comoros" },
    { label: "Congo (Congo-Brazzaville)", value: "Congo (Congo-Brazzaville)" },
    { label: "Costa Rica", value: "Costa Rica" },
    { label: "Croatia", value: "Croatia" },
    { label: "Cuba", value: "Cuba" },
    { label: "Cyprus", value: "Cyprus" },
    { label: "Czechia (Czech Republic)", value: "Czechia (Czech Republic)" },
    {
      label: "Democratic Republic of the Congo",
      value: "Democratic Republic of the Congo",
    },
    { label: "Denmark", value: "Denmark" },
    { label: "Djibouti", value: "Djibouti" },
    { label: "Dominica", value: "Dominica" },
    { label: "Dominican Republic", value: "Dominican Republic" },
    { label: "Ecuador", value: "Ecuador" },
    { label: "Egypt", value: "Egypt" },
    { label: "El Salvador", value: "El Salvador" },
    { label: "Equatorial Guinea", value: "Equatorial Guinea" },
    { label: "Eritrea", value: "Eritrea" },
    { label: "Estonia", value: "Estonia" },
    {
      label: "Eswatini (fmr. 'Swaziland')",
      value: "Eswatini (fmr. 'Swaziland')",
    },
    { label: "Ethiopia", value: "Ethiopia" },
    { label: "Fiji", value: "Fiji" },
    { label: "Finland", value: "Finland" },
    { label: "France", value: "France" },
    { label: "Gabon", value: "Gabon" },
    { label: "Gambia", value: "Gambia" },
    { label: "Georgia", value: "Georgia" },
    { label: "Germany", value: "Germany" },
    { label: "Ghana", value: "Ghana" },
    { label: "Greece", value: "Greece" },
    { label: "Grenada", value: "Grenada" },
    { label: "Guatemala", value: "Guatemala" },
    { label: "Guinea", value: "Guinea" },
    { label: "Guinea-Bissau", value: "Guinea-Bissau" },
    { label: "Guyana", value: "Guyana" },
    { label: "Haiti", value: "Haiti" },
    { label: "Honduras", value: "Honduras" },
    { label: "Hungary", value: "Hungary" },
    { label: "Iceland", value: "Iceland" },
    { label: "India", value: "India" },
    { label: "Indonesia", value: "Indonesia" },
    { label: "Iran", value: "Iran" },
    { label: "Iraq", value: "Iraq" },
    { label: "Ireland", value: "Ireland" },
    { label: "Israel", value: "Israel" },
    { label: "Italy", value: "Italy" },
    { label: "Jamaica", value: "Jamaica" },
    { label: "Japan", value: "Japan" },
    { label: "Jordan", value: "Jordan" },
    { label: "Kazakhstan", value: "Kazakhstan" },
    { label: "Kenya", value: "Kenya" },
    { label: "Kiribati", value: "Kiribati" },
    { label: "Kosovo", value: "Kosovo" },
    { label: "Kuwait", value: "Kuwait" },
    { label: "Kyrgyzstan", value: "Kyrgyzstan" },
    { label: "Laos", value: "Laos" },
    { label: "Latvia", value: "Latvia" },
    { label: "Lebanon", value: "Lebanon" },
    { label: "Lesotho", value: "Lesotho" },
    { label: "Liberia", value: "Liberia" },
    { label: "Libya", value: "Libya" },
    { label: "Liechtenstein", value: "Liechtenstein" },
    { label: "Lithuania", value: "Lithuania" },
    { label: "Luxembourg", value: "Luxembourg" },
    { label: "Madagascar", value: "Madagascar" },
    { label: "Malawi", value: "Malawi" },
    { label: "Malaysia", value: "Malaysia" },
    { label: "Maldives", value: "Maldives" },
    { label: "Mali", value: "Mali" },
    { label: "Malta", value: "Malta" },
    { label: "Marshall Islands", value: "Marshall Islands" },
    { label: "Mauritania", value: "Mauritania" },
    { label: "Mauritius", value: "Mauritius" },
    { label: "Mexico", value: "Mexico" },
    { label: "Micronesia", value: "Micronesia" },
    { label: "Moldova", value: "Moldova" },
    { label: "Monaco", value: "Monaco" },
    { label: "Mongolia", value: "Mongolia" },
    { label: "Montenegro", value: "Montenegro" },
    { label: "Morocco", value: "Morocco" },
    { label: "Mozambique", value: "Mozambique" },
    { label: "Myanmar (formerly Burma)", value: "Myanmar (formerly Burma)" },
    { label: "Namibia", value: "Namibia" },
    { label: "Nauru", value: "Nauru" },
    { label: "Nepal", value: "Nepal" },
    { label: "Netherlands", value: "Netherlands" },
    { label: "New Zealand", value: "New Zealand" },
    { label: "Nicaragua", value: "Nicaragua" },
    { label: "Niger", value: "Niger" },
    { label: "Nigeria", value: "Nigeria" },
    { label: "North Korea", value: "North Korea" },
    { label: "North Macedonia", value: "North Macedonia" },
    { label: "Norway", value: "Norway" },
    { label: "Oman", value: "Oman" },
    { label: "Pakistan", value: "Pakistan" },
    { label: "Palau", value: "Palau" },
    { label: "Palestine State", value: "Palestine State" },
    { label: "Panama", value: "Panama" },
    { label: "Papua New Guinea", value: "Papua New Guinea" },
    { label: "Paraguay", value: "Paraguay" },
    { label: "Peru", value: "Peru" },
    { label: "Philippines", value: "Philippines" },
    { label: "Poland", value: "Poland" },
    { label: "Portugal", value: "Portugal" },
    { label: "Qatar", value: "Qatar" },
    { label: "Romania", value: "Romania" },
    { label: "Russia", value: "Russia" },
    { label: "Rwanda", value: "Rwanda" },
    { label: "Saint Kitts and Nevis", value: "Saint Kitts and Nevis" },
    { label: "Saint Lucia", value: "Saint Lucia" },
    {
      label: "Saint Vincent and the Grenadines",
      value: "Saint Vincent and the Grenadines",
    },
    { label: "Samoa", value: "Samoa" },
    { label: "San Marino", value: "San Marino" },
    { label: "Sao Tome and Principe", value: "Sao Tome and Principe" },
    { label: "Saudi Arabia", value: "Saudi Arabia" },
    { label: "Senegal", value: "Senegal" },
    { label: "Serbia", value: "Serbia" },
    { label: "Seychelles", value: "Seychelles" },
    { label: "Sierra Leone", value: "Sierra Leone" },
    { label: "Singapore", value: "Singapore" },
    { label: "Slovakia", value: "Slovakia" },
    { label: "Slovenia", value: "Slovenia" },
    { label: "Solomon Islands", value: "Solomon Islands" },
    { label: "Somalia", value: "Somalia" },
    { label: "South Africa", value: "South Africa" },
    { label: "South Korea", value: "South Korea" },
    { label: "South Sudan", value: "South Sudan" },
    { label: "Spain", value: "Spain" },
    { label: "Sri Lanka", value: "Sri Lanka" },
    { label: "Sudan", value: "Sudan" },
    { label: "Suriname", value: "Suriname" },
    { label: "Sweden", value: "Sweden" },
    { label: "Switzerland", value: "Switzerland" },
    { label: "Syria", value: "Syria" },
    { label: "Tajikistan", value: "Tajikistan" },
    { label: "Tanzania", value: "Tanzania" },
    { label: "Thailand", value: "Thailand" },
    { label: "Timor-Leste", value: "Timor-Leste" },
    { label: "Togo", value: "Togo" },
    { label: "Tonga", value: "Tonga" },
    { label: "Trinidad and Tobago", value: "Trinidad and Tobago" },
    { label: "Tunisia", value: "Tunisia" },
    { label: "Turkey", value: "Turkey" },
    { label: "Turkmenistan", value: "Turkmenistan" },
    { label: "Tuvalu", value: "Tuvalu" },
    { label: "Uganda", value: "Uganda" },
    { label: "Ukraine", value: "Ukraine" },
    { label: "United Arab Emirates", value: "United Arab Emirates" },
    { label: "United Kingdom", value: "United Kingdom" },
    { label: "USA", value: "USA" },
    { label: "Uruguay", value: "Uruguay" },
    { label: "Uzbekistan", value: "Uzbekistan" },
    { label: "Vanuatu", value: "Vanuatu" },
    { label: "Vatican City", value: "Vatican City" },
    { label: "Venezuela", value: "Venezuela" },
    { label: "Vietnam", value: "Vietnam" },
    { label: "Yemen", value: "Yemen" },
    { label: "Zambia", value: "Zambia" },
    { label: "Zimbabwe", value: "Zimbabwe" },
  ];

  const categoryTypeOptions = [
    { label: "Include", value: "include" },
    { label: "Exclude", value: "exclude" },
  ];

  useEffect(() => {
    listWebsites();
    listTypes();
    listTypesDropdown();
    listStatusDropdown();
    listCategoriesDropdown();
    listTeamMemberDropdown();
    listCategories();
  }, [currentPage, searchValue, entries]);

  useEffect(() => {
    setCurrentPage(1);
    listWebsites();
  }, [resetLoading, sortBy, sortOrder]);

  const handleUpload = async (e) => {
    const token = localStorage.getItem("token");
    setLoading(true);
    const file = e.target.files[0];

    if (!file) {
      toast.error("Please Select a file");
      return;
    }

    if (!file.name.match(/\.(xls|xlsx)$/)) {
      toast.error("Please upload an Excel file with .xls or .xlsx format.");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await axios.post(
        ` ${process.env.REACT_APP_SERVER_API}/api/web/store-bulk`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      e.target.value = null;
      listWebsites();
      setLoading(false);
      toast.success("Uploaded Successfully");
    } catch (error) {
      e.target.value = null;
      console.error("Error uploading file:", error);
      setLoading(false);
      toast.error("Uploading Failed");
    }
  };

  const handleCatFilterChange = (selectedOptions) => {
    setSelectedFilterCategoriesOptions(selectedOptions);
    const selectedIds = selectedOptions.map((option) => option.value);
    setCatValue(selectedIds);
  };

  const handleCountryChange = (selectedFilterCountryOptions) => {
    setSelectedFilterCountryOptions(selectedFilterCountryOptions);
    setCountryValue(selectedFilterCountryOptions.value);
  };

  const handleCategoryTypeChange = (selectedOptions) => {
    setSelectedFilterCategoryTypeOptions(selectedOptions);
    const selectedIds = selectedOptions.map((option) => option.value);
    setCategoryValue(selectedIds);
  };

  const handleTypeFilterChange = (selectedFilterTypesOptions) => {
    setSelectedFilterTypesOptions(selectedFilterTypesOptions);
    setTypeValue(selectedFilterTypesOptions.value);
  };

  const handleTeamMemberChange = (SelectedFilterTeamMemberOptions) => {
    setSelectedFilterTeamMemberOptions(SelectedFilterTeamMemberOptions);
    setTeamMemberValue(SelectedFilterTeamMemberOptions.value);
    console.log(SelectedFilterTeamMemberOptions, teamMemberValue, "plo");
  };

  const handleStatusChange = (linkStatusSelectedOptions) => {
    setLinkStatusSelectedOptions(linkStatusSelectedOptions);
  };

  const listWebsites = async () => {
    setLoading(true);
    try {
      const categoryIdString = catValue.join(",");
      const categoryTypeString = categoryValue.join(",");
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_API}/api/web/list`,
        {
          params: {
            sortOrder: sortOrder,
            sortBy: sortBy,
            pageNo: currentPage,
            pageSize: entries,
            searchValue: searchValue,
            domainRatingStart: domainRatingStart,
            domainRatingEnd: domainRatingEnd,
            domainAuthorityStart: domainAuthorityStart,
            domainAuthorityEnd: domainAuthorityEnd,
            spamScoreStart: spamScoreStart,
            spamScoreEnd: spamScoreEnd,
            priceStart: priceStart,
            priceEnd: priceEnd,
            authorityScoreStart: authorityScoreStart,
            authorityScoreEnd: authorityScoreEnd,
            ahrefsStart: ahrefsStart,
            ahrefsEnd: ahrefsEnd,
            semrushStart: semrushStart,
            semrushEnd: semrushEnd,
            linkStatus: linkStatusSelectedOptions.value,
            teamMember: teamMemberValue,
            typeId: typeValue,
            categoryId: categoryIdString,
            country: countryValue,
            language: languageValue,
            categoryType: categoryTypeString,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setData(res?.data?.result?.data);
      setToValue(res?.data?.result?.pagination?.total);
      setPaginationValue(res?.data?.result?.pagination);
    } catch (err) {
      console.error("Error fetching websites:", err);
      toast.error(err.response?.data?.message || "Error fetching websites");
    } finally {
      setLoading(false);
    }
    setDropdownOpenDa(false);
    setDropdownOpenDr(false);
    setDropdownOpenSS(false);
    setDropdownOpenPrice(false);
    setDropdownOpenAS(false);
    setDropdownOpenahrefs(false);
    setDropdownOpenSemrush(false);
    setDropdownOpenTeamMember(false);
  };

  const sortByClick = async (sortByFunc) => {
    setLoading(true);
    setCurrentPage(1);
    const newSortOrder = sortOrder === "asc" ? "dsc" : "asc";
    setSortOrder(newSortOrder);
    setSortBy(sortByFunc);
  };

  const listCategories = () => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER_API}/api/category/list?pageNo=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setCategoriesData(res.data.result.data);
      })
      .catch((err) => toast.error(err.response.data.message));
  };

  const listCategoriesDropdown = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_API}/api/category/list-all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setCategoriesDropdownData(res.data.result);
      })
      .catch((err) => toast.error(err.response.data.message));
  };

  const listTeamMemberDropdown = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_API}/api/user/list-all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setTeamMemberDropdownData(res.data.result);
      })
      .catch((err) => toast.error(err.response.data.message));
  };

  const listTypes = () => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER_API}/api/type/list?pageNo=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setTypesData(res.data.result.data);
      })
      .catch((err) => toast.error(err.response.data.message));
  };

  const listTypesDropdown = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_API}/api/type/list-all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setTypesDataDropdown(res.data.result);
      })
      .catch((err) => toast.error(err.response.data.message));
  };

  const typesDropdownOptions = [];
  typesDataDropdown.map((type) =>
    typesDropdownOptions.push({ value: type._id, label: type.TypeName })
  );

  const typesOptions = [];
  typesData.map((type) =>
    typesOptions.push({ value: type._id, label: type.TypeName })
  );

  const categoriesOptions = [];
  categoriesData.map((type) =>
    categoriesOptions.push({
      value: type.parent._id,
      label: type.parent.categoryName,
    })
  );

  const categoriesDropdownOptions = [];
  categoriesDropdownData.map((type) =>
    categoriesDropdownOptions.push({
      value: type._id,
      label: type.categoryName,
    })
  );

  const teamMemberDropdownOptions = [];
  teamMemberDropdownData.map((type) =>
    teamMemberDropdownOptions.push({ value: type._id, label: type.name })
  );

  const handleDelete = (id) => {
    setSelectedId(id);
    handleShowModal();
  };

  const handleAdd = () => {
    navigate("/addwebsite");
  };

  const handleUpdate = (user) => {
    navigate("/editwebsite", { state: { userData: user } });
  };

  const handleUpdateRow = (user) => {
    navigate("/editwebsiteRow", { state: { userData: user } });
  };

  const handleDeleteSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .post(
        `${process.env.REACT_APP_SERVER_API}/api/web/delete`,
        { id: selectedId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        const updatedData = data.filter(
          (user) => !selectedId.includes(user._id)
        );
        setData(updatedData);
        setLoading(false);
        toast.success("Deleted Successfully");
        handleCloseModal();
        listWebsites();
      })
      .catch((err) => toast.error(err.response.data.message))
      .finally(() => setLoading(false));
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchChange = (e) => {
    setCurrentPage(1);
    setSearchValue(e.target.value);
  };

  const handleDaReset = () => {
    setDomainAuthorityStart("");
    setDomainAuthorityEnd("");
    setResetLoading(!resetLoading);
  };

  const handleDrReset = () => {
    setDomainRatingStart("");
    setDomainRatingEnd("");
    setResetLoading(!resetLoading);
  };

  const handleAsReset = () => {
    setauthorityScoreStart("");
    setAuthorityScoreEnd("");
    setResetLoading(!resetLoading);
  };

  const handleSsReset = () => {
    setSpamScoreStart("");
    setSpamScoreEnd("");
    setResetLoading(!resetLoading);
  };

  const handleAhrefsReset = () => {
    setAhrefsStart("");
    setAhrefsEnd("");
    setResetLoading(!resetLoading);
  };

  const handleSemrushReset = () => {
    setSemrushStart("");
    setSemrushEnd("");
    setResetLoading(!resetLoading);
  };

  const handleCountryReset = () => {
    setCountryValue("");
    setSelectedFilterCountryOptions({ value: "", label: "" });
    setResetLoading(!resetLoading);
  };

  const handleCategoryReset = () => {
    setCatValue([]);
    setSelectedFilterCategoriesOptions([]);
    setResetLoading(!resetLoading);
  };

  const handleTypeReset = () => {
    setTypeValue("");
    setSelectedFilterTypesOptions({ value: "", label: "" });
    setResetLoading(!resetLoading);
  };

  const handleTeamMemberReset = () => {
    setTeamMemberValue("");
    setSelectedFilterTeamMemberOptions({ value: "", label: "" });
    setResetLoading(!resetLoading);
  };
  const handleLinkStatusReset = () => {
    setLinkStatusSelectedOptions({ label: "", value: "" });
    setResetLoading(!resetLoading);
  };

  const handleReset = () => {
    setDomainRatingStart("");
    setDomainRatingEnd("");

    setDomainAuthorityStart("");
    setDomainAuthorityEnd("");

    setSpamScoreStart("");
    setSpamScoreEnd("");

    setPriceStart("");
    setPriceEnd("");

    setHrefs("");
    setSemrush("");

    setauthorityScoreStart("");
    setAuthorityScoreEnd("");
    setAhrefsStart("");
    setAhrefsEnd("");

    setSemrushStart("");
    setSemrushEnd("");

    setSelectedFilterCategoriesOptions([]);
    setSelectedFilterCountryOptions({});
    setSelectedFilterCategoryTypeOptions({
      label: "Include",
      value: "include",
    });
    setCatValue([]);
    setCountryValue("");
    setTypeValue("");
    setTeamMemberValue("");
    setLinkStatusSelectedOptions({ label: "", value: "" });
    setSelectedFilterTypesOptions({});
    setSelectedFilterTeamMemberOptions({});
    setLoading(false);
    setCurrentPage(1);
    setResetLoading(!resetLoading);
    setSelectedRows([])
  };

  const highlightSearchValue = (value) => {
    if (!searchValue) return value;

    const regex = new RegExp(searchValue, "gi");
    return typeof value === "string" ? (
      <span
        dangerouslySetInnerHTML={{
          __html: value.replace(
            regex,
            (match) =>
              `<mark style="background-color: yellow; font-weight: bold;">${match}</mark>`
          ),
        }}
      />
    ) : (
      value
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    setResetLoading(!resetLoading);
    //listWebsites();
  };

  function convertToStringWithSuffix(number) {
    return number;
    const suffixes = {
      1000: "K",
      1000000: "M",
      // Add more suffixes if needed
    };

    for (const [multiplier, suffix] of Object.entries(suffixes)) {
      if (number % multiplier === 0) {
        const quotient = number / multiplier;
        if (quotient % 10 === 0) {
          return quotient / 10 + "0" + suffix;
        } else {
          return quotient + suffix;
        }
      }
    }

    return JSON.stringify(number);
  }

  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "20px",
    }),
  };

  return (
    <div className="container-fluid" id="content-body">
      <Circles
        height="80"
        width="80"
        color="#3155a7"
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass="loading-spinner-overlay"
        visible={loading}
      />
      <div className="row ">
        <Sidebar />
        <>
          <div className="col" style={{ height: "100vh", overflow: "auto" }}>
            <div className="mt-2">
              <Card id="main-card">
                <CardHeader
                  className="filter-adjustment"
                  style={{ paddingTop: "2px", paddingBottom: "2px" }}
                >
                  <div
                    className="d-flex justify-content-between"
                    id="searchbar"
                  >
                    <span>
                      <input
                        type="text"
                        autoComplete="off"
                        name="search0"
                        id="search0"
                        style={{
                          height: "40px",
                          borderRadius: "5px",
                          border: "1px solid #dbdbdb",
                          padding: "10px",
                        }}
                        placeholder="Search"
                        onChange={handleSearchChange}
                      />

                      <select
                        className="mx-2"
                        id="selector"
                        value={entries}
                        onChange={handleEntriesChange}
                        style={{
                          border: "1px solid hsl(0, 0%, 80%)",
                          cursor: "pointer",
                          height: "35px",
                          padding: "4px",
                          fontSize: "14px",
                          background: "hsl(0, 0%, 100%)",
                          borderRadius: "5px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <option value={10}>Per Page: 10</option>
                        <option value={25}>Per Page: 25</option>
                        <option value={50}>Per Page: 50</option>
                        <option value={100}>Per Page: 100</option>
                      </select>
                    </span>
                    <div className="d-flex flex-wrap" id="btns3">
                      {checkPermissions("Admin") ||
                      checkPermissions("Editor") ? (
                        <div>
                          {selectedRows.length !== 0 ? (
                            ""
                          ) : (
                            <button
                              onClick={handleAdd}
                              style={{
                                backgroundColor: "#3155a7",
                                color: "#fff",
                              }}
                              className="my-1 mx-1 btn btn-sm"
                            >
                              <BsPlus /> Add Websites
                            </button>
                          )}
                        </div>
                      ) : null}
                      <Form.Control
                        name="profilePicture"
                        type="file"
                        style={{ display: "none" }}
                        ref={fileInputRefProfile}
                        onChange={handleUpload}
                      />
                      {checkPermissions("Admin") ||
                      checkPermissions("Editor") ? (
                        <div>
                          {selectedRows.length !== 0 ? (
                            ""
                          ) : (
                            <button
                              onClick={() =>
                                fileInputRefProfile.current?.click()
                              }
                              style={{
                                backgroundColor: "#3155a7",
                                color: "#fff",
                              }}
                              className="my-1 mx-1 btn btn-sm"
                            >
                              Upload Data <BsPlus />
                            </button>
                          )}
                        </div>
                      ) : null}
                      {checkPermissions("Admin") ||
                      checkPermissions("Editor") ? (
                        <div>
                          {selectedRows.length === 0 ? (
                            ""
                          ) : (
                            <button
                              onClick={() => handleDelete(selectedRows)}
                              className="my-1 mx-1 btn btn-sm"
                              style={{
                                backgroundColor: "rgb(78 89 113)",
                                color: "#fff",
                              }}
                              disabled={selectedRows.length === 0}
                            >
                              Delete Selected
                            </button>
                          )}
                        </div>
                      ) : null}
                      {selectedRows.length !== 0 ? (
                        ""
                      ) : (
                        <a
                          id="downloadSampleBtn"
                          className="mx-1 my-1 btn btn-primary btn-sm"
                          href="#"
                          onClick={handleDownloadShowModal}
                        >
                          Download sample
                        </a>
                      )}
                      {/*<a
                        className="mx-1 my-1 btn btn-primary btn-sm"
                        href={`${process.env.REACT_APP_SERVER_API}/api/web/download?pageNo=${currentPage}&searchValue=${searchValue}&domainRatingStart=${domainRatingStart}&domainRatingEnd=${domainRatingEnd}&domainAuthorityStart=${domainAuthorityStart}&domainAuthorityEnd=${domainAuthorityEnd}&spamScoreStart=${spamScoreStart}&spamScoreEnd=${spamScoreEnd}&priceStart=${priceStart}&priceEnd=${priceEnd}&authorityScoreStart=${authorityScoreStart}&authorityScoreEnd=${authorityScoreEnd}&ahrefs=${ahrefs}&Semrush=${semrush}&LinkStatus=${linkStatusSelectedOptions.value}&TeamMember=${teamMember}&typeId=${typeValue}&categoryId=${catValue}`}
                        target="_blank"
                      >
                        Download sample
                      </a>*/}
                      
                        <button
                          onClick={handleReset}
                          type="button"
                          className="mx-1 my-1 btn btn-sm btn-secondary"
                        >
                          Reset Filters
                        </button>
                      
                      {selectedRows.length !== 0 ? (
                        ""
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-primary mx-1 btn-sm my-1"
                          onClick={handleSubmit}
                        >
                          Apply
                        </button>
                      )}
                    </div>
                  </div>

                  <form onSubmit={handleSubmit} id="filter-form">
                    <Card
                      className="mt-1"
                      style={{
                        borderRadius: "0",
                        border: "none",
                        background: "#f8f8f8",
                      }}
                    >
                      <div className="row m-0">
                        <div className="col-lg-2 col-md-3 col-sm-6 col-xs-6 p-1">
                          <div className="dropdown">
                            <label
                              onClick={() => handleDropdown("da")}
                              ref={dropdownRef}
                              style={{
                                border: `1px solid ${
                                  dropdownOpenDa
                                    ? "rgb(49, 85, 167)"
                                    : "hsl(0, 0%, 80%)"
                                }`,
                                cursor: "pointer",
                                display: "flex",
                                height: "38px",
                                padding: "4px",
                                alignItems: "center",
                                justifyContent: "center",
                                fontSize: "14px",
                                background:
                                  domainAuthorityStart || domainAuthorityEnd
                                    ? "lightblue"
                                    : "hsl(0, 0%, 100%)",
                                borderRadius: "5px",
                              }}
                            >
                              DA{" "}
                              {!domainAuthorityStart
                                ? ""
                                : "( From " + domainAuthorityStart}
                              {!domainAuthorityStart &&
                                domainAuthorityEnd &&
                                "("}
                              {domainAuthorityStart &&
                                !domainAuthorityEnd &&
                                ")"}
                              {!domainAuthorityEnd
                                ? ""
                                : " To " + domainAuthorityEnd + ")"}
                              <MdOutlineArrowDropDown
                                style={{ fontSize: "20px", marginLeft: "5px" }}
                              />
                            </label>
                            {!domainAuthorityStart && !domainAuthorityEnd ? (
                              ""
                            ) : (
                              <span
                                style={{
                                  position: "absolute",
                                  top: "-7px",
                                  right: 0,
                                  color: "#fff",
                                  background: "red",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "50%",
                                }}
                              >
                                <MdClose onClick={handleDaReset} />
                              </span>
                            )}
                            {dropdownOpenDa && (
                              <div
                                className="dropdown-content"
                                onClick={handleDropdownClick}
                              >
                                <div style={{ display: "flex" }}>
                                  <input
                                    type="number"
                                    className="number-input"
                                    id="domainAuthorityStart"
                                    min="0"
                                    step="1"
                                    value={domainAuthorityStart}
                                    onChange={(e) =>
                                      setDomainAuthorityStart(e.target.value)
                                    }
                                    placeholder="From"
                                  />
                                  <input
                                    type="number"
                                    className="number-input"
                                    id="domainAuthorityEnd"
                                    min="0"
                                    max="100"
                                    step="1"
                                    value={domainAuthorityEnd}
                                    onChange={(e) =>
                                      setDomainAuthorityEnd(e.target.value)
                                    }
                                    placeholder="To"
                                  />
                                </div>
                                <hr />
                                <button
                                  className="submit-button btn btn-primary"
                                  onClick={listWebsites}
                                >
                                  Apply
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-6 col-xs-6 p-1">
                          <div className="dropdown">
                            <label
                              onClick={() => handleDropdown("dr")}
                              ref={dropdownRef}
                              style={{
                                border: `1px solid ${
                                  dropdownOpenDr
                                    ? "rgb(49, 85, 167)"
                                    : "hsl(0, 0%, 80%)"
                                }`,
                                cursor: "pointer",
                                display: "flex",
                                height: "38px",
                                padding: "4px",
                                alignItems: "center",
                                justifyContent: "center",
                                fontSize: "14px",
                                background:
                                  domainRatingStart || domainRatingEnd
                                    ? "lightblue"
                                    : "hsl(0, 0%, 100%)",
                                borderRadius: "5px",
                              }}
                            >
                              DR{" "}
                              {!domainRatingStart
                                ? ""
                                : "( From " + domainRatingStart}
                              {!domainRatingStart && domainRatingEnd && "("}
                              {domainRatingStart && !domainRatingEnd && ")"}
                              {!domainRatingEnd
                                ? ""
                                : " To " + domainRatingEnd + ")"}
                              <MdOutlineArrowDropDown
                                style={{ fontSize: "20px", marginLeft: "5px" }}
                              />
                            </label>
                            {!domainRatingStart && !domainRatingEnd ? (
                              ""
                            ) : (
                              <span
                                style={{
                                  position: "absolute",
                                  top: "-7px",
                                  right: 0,
                                  color: "#fff",
                                  background: "red",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "50%",
                                }}
                              >
                                <MdClose onClick={handleDrReset} />
                              </span>
                            )}
                            {dropdownOpenDr && (
                              <div
                                className="dropdown-content"
                                onClick={handleDropdownClick}
                              >
                                <div style={{ display: "flex" }}>
                                  <input
                                    type="number"
                                    className="number-input"
                                    id="domainRatingStart"
                                    min="0"
                                    step="1"
                                    value={domainRatingStart}
                                    placeholder="From"
                                    onChange={(e) =>
                                      setDomainRatingStart(e.target.value)
                                    }
                                  />
                                  <input
                                    type="number"
                                    className="number-input"
                                    id="domainRatingEnd"
                                    min="0"
                                    max="100"
                                    step="1"
                                    value={domainRatingEnd}
                                    placeholder="To"
                                    onChange={(e) =>
                                      setDomainRatingEnd(e.target.value)
                                    }
                                  />
                                </div>
                                <hr />
                                <button
                                  className="submit-button btn btn-primary"
                                  onClick={listWebsites}
                                >
                                  Apply
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-6 col-xs-6 p-1">
                          <div className="dropdown">
                            <label
                              onClick={() => handleDropdown("as")}
                              ref={dropdownRef}
                              style={{
                                border: `1px solid ${
                                  dropdownOpenAS
                                    ? "rgb(49, 85, 167)"
                                    : "hsl(0, 0%, 80%)"
                                }`,
                                cursor: "pointer",
                                display: "flex",
                                height: "38px",
                                padding: "4px",
                                alignItems: "center",
                                justifyContent: "center",
                                fontSize: "14px",
                                background:
                                  authorityScoreStart || authorityScoreEnd
                                    ? "lightblue"
                                    : "hsl(0, 0%, 100%)",
                                borderRadius: "5px",
                              }}
                            >
                              AS{" "}
                              {!authorityScoreStart
                                ? ""
                                : "( From " + authorityScoreStart}
                              {!authorityScoreStart && authorityScoreEnd && "("}
                              {authorityScoreStart && !authorityScoreEnd && ")"}
                              {!authorityScoreEnd
                                ? ""
                                : " To " + authorityScoreEnd + ")"}
                              <MdOutlineArrowDropDown
                                style={{ fontSize: "20px", marginLeft: "5px" }}
                              />
                            </label>
                            {!authorityScoreStart && !authorityScoreEnd ? (
                              ""
                            ) : (
                              <span
                                style={{
                                  position: "absolute",
                                  top: "-7px",
                                  right: 0,
                                  color: "#fff",
                                  background: "red",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "50%",
                                }}
                              >
                                <MdClose onClick={handleAsReset} />
                              </span>
                            )}
                            {dropdownOpenAS && (
                              <div
                                className="dropdown-content"
                                onClick={handleDropdownClick}
                              >
                                <div style={{ display: "flex" }}>
                                  <input
                                    type="number"
                                    className="number-input"
                                    id="DrStart"
                                    min="0"
                                    step="1"
                                    value={authorityScoreStart}
                                    onChange={(e) =>
                                      setauthorityScoreStart(e.target.value)
                                    }
                                    placeholder="From"
                                  />
                                  <input
                                    type="number"
                                    className="number-input"
                                    id="DrEnd"
                                    min="0"
                                    max="100"
                                    step="1"
                                    value={authorityScoreEnd}
                                    onChange={(e) =>
                                      setAuthorityScoreEnd(e.target.value)
                                    }
                                    placeholder="To"
                                  />
                                </div>
                                <hr />
                                <button
                                  className="submit-button btn btn-primary"
                                  onClick={listWebsites}
                                >
                                  Apply
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-6 col-xs-6 p-1">
                          <div className="dropdown">
                            <label
                              onClick={() => handleDropdown("ss")}
                              style={{
                                border: `1px solid ${
                                  dropdownOpenSS
                                    ? "rgb(49, 85, 167)"
                                    : "hsl(0, 0%, 80%)"
                                }`,
                                cursor: "pointer",
                                display: "flex",
                                height: "38px",
                                padding: "4px",
                                alignItems: "center",
                                justifyContent: "center",
                                fontSize: "14px",
                                background:
                                  spamScoreStart || spamScoreEnd
                                    ? "lightblue"
                                    : "hsl(0, 0%, 100%)",
                                borderRadius: "5px",
                              }}
                            >
                              SS{" "}
                              {!spamScoreStart
                                ? ""
                                : "( From " + spamScoreStart}
                              {!spamScoreStart && spamScoreEnd && "("}
                              {spamScoreStart && !spamScoreEnd && ")"}
                              {!spamScoreEnd ? "" : " To " + spamScoreEnd + ")"}
                              <MdOutlineArrowDropDown
                                style={{ fontSize: "20px", marginLeft: "5px" }}
                              />
                            </label>
                            {!spamScoreStart && !spamScoreEnd ? (
                              ""
                            ) : (
                              <span
                                style={{
                                  position: "absolute",
                                  top: "-7px",
                                  right: 0,
                                  color: "#fff",
                                  background: "red",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "50%",
                                }}
                              >
                                <MdClose onClick={handleSsReset} />
                              </span>
                            )}
                            {dropdownOpenSS && (
                              <div
                                className="dropdown-content"
                                onClick={handleDropdownClick}
                              >
                                <div style={{ display: "flex" }}>
                                  <input
                                    type="number"
                                    className="number-input"
                                    id="spamScoreStart"
                                    min="0"
                                    step="1"
                                    value={spamScoreStart}
                                    onChange={(e) =>
                                      setSpamScoreStart(e.target.value)
                                    }
                                    placeholder="From"
                                  />
                                  <input
                                    type="number"
                                    className="number-input"
                                    id="spamScoreEnd"
                                    min="0"
                                    max="100"
                                    step="1"
                                    value={spamScoreEnd}
                                    onChange={(e) =>
                                      setSpamScoreEnd(e.target.value)
                                    }
                                    placeholder="To"
                                  />
                                </div>
                                <hr />
                                <button
                                  className="submit-button btn btn-primary"
                                  onClick={listWebsites}
                                >
                                  Apply
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-6 col-xs-6 p-1">
                          <div className="dropdown">
                            <label
                              onClick={() => handleDropdown("hr")}
                              ref={dropdownRef}
                              style={{
                                border: `1px solid ${
                                  dropdownOpenahrefs
                                    ? "rgb(49, 85, 167)"
                                    : "hsl(0, 0%, 80%)"
                                }`,
                                cursor: "pointer",
                                display: "flex",
                                height: "38px",
                                padding: "4px",
                                alignItems: "center",
                                justifyContent: "center",
                                fontSize: "14px",
                                background:
                                  ahrefsStart || ahrefsEnd
                                    ? "lightblue"
                                    : "hsl(0, 0%, 100%)",
                                borderRadius: "5px",
                                whiteSpace: "nowrap",
                              }}
                              title={
                                !ahrefsEnd
                                  ? "Ahrefs Traffic"
                                  : "Ahrefs Traffic (" + ahrefsEnd + ")"
                              }
                            >
                              Ahrefs Traffic{" "}
                              {!ahrefsStart
                                ? ""
                                : "(From " +
                                  (ahrefsStart > 1000
                                    ? ahrefsStart.slice(0, 4) + "..."
                                    : ahrefsStart)}
                              {!ahrefsStart && ahrefsEnd && "("}
                              {ahrefsStart && !ahrefsEnd && ")"}
                              {!ahrefsEnd
                                ? ""
                                : " To " +
                                  (ahrefsEnd > 1000
                                    ? ahrefsEnd.slice(0, 4) + "..."
                                    : ahrefsEnd) +
                                  ")"}
                              <MdOutlineArrowDropDown
                                style={{ fontSize: "20px", marginLeft: "5px" }}
                              />
                            </label>
                            {!ahrefsStart && !ahrefsEnd ? (
                              ""
                            ) : (
                              <span
                                style={{
                                  position: "absolute",
                                  top: "-7px",
                                  right: 0,
                                  color: "#fff",
                                  background: "red",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "50%",
                                }}
                              >
                                <MdClose onClick={handleAhrefsReset} />
                              </span>
                            )}
                            {dropdownOpenahrefs && (
                              <div
                                className="dropdown-content"
                                onClick={handleDropdownClick}
                              >
                                <div style={{ display: "flex" }}>
                                  <input
                                    type="number"
                                    className="number-input"
                                    id="ahrefsStart"
                                    min="0"
                                    step="1"
                                    value={ahrefsStart}
                                    onChange={(e) =>
                                      setAhrefsStart(e.target.value)
                                    }
                                    placeholder="From"
                                  />
                                  <input
                                    type="number"
                                    className="number-input"
                                    id="ahrefsEnd"
                                    min="0"
                                    step="1"
                                    value={ahrefsEnd}
                                    style={{
                                      textOverflow: " ellipsis",
                                      width: "100%",
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                    }}
                                    title={ahrefsEnd}
                                    onChange={(e) =>
                                      setAhrefsEnd(e.target.value)
                                    }
                                    placeholder="To"
                                  />
                                </div>
                                <hr />
                                <button
                                  className="submit-button btn btn-primary"
                                  onClick={listWebsites}
                                >
                                  Apply
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-1 col-md-3 col-sm-6 col-xs-6 p-1">
                          <div className="dropdown">
                            <label
                              onClick={() => handleDropdown("se")}
                              ref={dropdownRef}
                              style={{
                                border: `1px solid ${
                                  dropdownOpenSemrush
                                    ? "rgb(49, 85, 167)"
                                    : "hsl(0, 0%, 80%)"
                                }`,
                                cursor: "pointer",
                                display: "flex",
                                height: "38px",
                                padding: "4px",
                                alignItems: "center",
                                justifyContent: "center",
                                fontSize: "14px",
                                background:
                                  semrushStart || semrushEnd
                                    ? "lightblue"
                                    : "hsl(0, 0%, 100%)",
                                borderRadius: "5px",
                                whiteSpace: "nowrap",
                              }}
                              title={
                                !semrushEnd
                                  ? "Semrush Traffic"
                                  : "Semrush Traffic (" + semrushEnd + ")"
                              }
                            >
                              Semrush Traffic{" "}
                              {!semrushStart
                                ? ""
                                : "(From " +
                                  (semrushStart > 1000
                                    ? semrushStart.slice(0, 4) + "..."
                                    : semrushStart)}
                              {!semrushStart && semrushEnd && "("}
                              {semrushStart && !semrushEnd && ")"}
                              {!semrushEnd
                                ? ""
                                : " To " +
                                  (semrushEnd > 1000
                                    ? semrushEnd.slice(0, 4) + "..."
                                    : semrushEnd) +
                                  ")"}
                              <MdOutlineArrowDropDown
                                style={{ fontSize: "20px", marginLeft: "5px" }}
                              />
                            </label>
                            {!semrushStart && !semrushEnd ? (
                              ""
                            ) : (
                              <span
                                style={{
                                  position: "absolute",
                                  top: "-7px",
                                  right: 0,
                                  color: "#fff",
                                  background: "red",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "50%",
                                }}
                              >
                                <MdClose onClick={handleSemrushReset} />
                              </span>
                            )}
                            {dropdownOpenSemrush && (
                              <div
                                className="dropdown-content"
                                onClick={handleDropdownClick}
                              >
                                <div style={{ display: "flex" }}>
                                  <input
                                    type="number"
                                    className="number-input"
                                    id="semrushStart"
                                    min="0"
                                    step="1"
                                    value={semrushStart}
                                    onChange={(e) =>
                                      setSemrushStart(e.target.value)
                                    }
                                    placeholder="From"
                                  />
                                  <input
                                    type="number"
                                    className="number-input"
                                    id="semrushEnd"
                                    min="0"
                                    step="1"
                                    value={semrushEnd}
                                    onChange={(e) =>
                                      setSemrushEnd(e.target.value)
                                    }
                                    placeholder="To"
                                  />
                                </div>
                                <hr />
                                <button
                                  className="submit-button btn btn-primary"
                                  onClick={listWebsites}
                                >
                                  Apply
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                        {/*PRICE*/}
                        <div className="col-lg-1 col-md-3 col-sm-6   col-xs-6 p-1">
                          <div
                            className="dropdown"
                            onClick={() => handleDropdown("p")}
                            ref={dropdownRef}
                          >
                            <label
                              style={{
                                border: `1px solid ${dropdownOpenPrice ? 'rgb(49, 85, 167)' : 'hsl(0, 0%, 80%)'}`,
                                cursor: "pointer",
                                display: "flex",
                                height: "38px",
                                padding: "4px",
                                alignItems: "center",
                                justifyContent: "center",
                                fontSize: "14px",
                                background: "hsl(0, 0%, 100%)",
                                borderRadius: "5px",
                              }}
                            >
                              Price{" "}
                              <MdOutlineArrowDropDown
                                style={{ fontSize: "20px", marginLeft: "5px" }}
                              />
                            </label>
                            {dropdownOpenPrice && (
                              <div
                                className="dropdown-content"
                                onClick={handleDropdownClick}
                              >
                                <div style={{ display: "flex" }}>
                                  <input
                                    type="number"
                                    className="number-input"
                                    id="priceStart"
                                    min="0"
                                    step="1"
                                    value={priceStart}
                                    onChange={(e) =>
                                      setPriceStart(e.target.value)
                                    }
                                    placeholder="From"
                                  />
                                  <input
                                    type="number"
                                    className="number-input"
                                    id="priceEnd"
                                    value={priceEnd}
                                    onChange={(e) =>
                                      setPriceEnd(e.target.value)
                                    }
                                    placeholder="To"
                                  />
                                </div>
                                <hr />
                                <button
                                  className="submit-button btn btn-primary"
                                  onClick={listWebsites}
                                >
                                  Apply
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row m-0">
                        <div className="col-lg-3 col-md-2 col-sm-12 p-1">
                          <label htmlFor="">Country</label>
                          {countryValue === "" ? (
                            ""
                          ) : (
                            <span
                              style={{
                                position: "absolute",
                                top: "36%",
                                left: "23.5%",
                                zIndex: "1",
                                color: "#fff",
                                background: "red",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "50%",
                              }}
                            >
                              <MdClose onClick={handleCountryReset} />
                            </span>
                          )}
                          <Select
                            options={countryOptions}
                            name="catFilter"
                            value={selectedFilterCountryOptions}
                            onChange={handleCountryChange}
                            placeholder="Country"
                          />
                        </div>
                        <div className="col-lg-3 col-md-2 col-sm-12 p-1">
                          <label htmlFor="">Types</label>
                          {typeValue === "" ? (
                            ""
                          ) : (
                            <span
                              style={{
                                position: "absolute",
                                top: "36%",
                                left: "48.5%",
                                zIndex: "1",
                                color: "#fff",
                                background: "red",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "50%",
                              }}
                            >
                              <MdClose onClick={handleTypeReset} />
                            </span>
                          )}
                          <Select
                            options={typesDropdownOptions}
                            name="typeFilter"
                            value={selectedFilterTypesOptions}
                            onChange={handleTypeFilterChange}
                            placeholder="Types"
                          />
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12 p-1">
                          <label htmlFor="">Link Status</label>
                          {linkStatusSelectedOptions.label === "" ? (
                            ""
                          ) : (
                            <span
                              style={{
                                position: "absolute",
                                top: "36%",
                                left: "73%",
                                zIndex: "1",
                                color: "#fff",
                                background: "red",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "50%",
                              }}
                            >
                              <MdClose onClick={handleLinkStatusReset} />
                            </span>
                          )}
                          <Select
                            required
                            options={linkStatusOptions}
                            value={linkStatusSelectedOptions}
                            onChange={handleStatusChange}
                            placeholder="Type to search"
                          />
                        </div>
                        <div className="col-lg-3 col-md-2 col-sm-12 p-1">
                          <label htmlFor="">Team Members</label>
                          {teamMemberValue === "" ? (
                            ""
                          ) : (
                            <span
                              style={{
                                position: "absolute",
                                top: "36%",
                                right: 0,
                                zIndex: "1",
                                color: "#fff",
                                background: "red",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "50%",
                              }}
                            >
                              <MdClose onClick={handleTeamMemberReset} />
                            </span>
                          )}
                          <Select
                            options={teamMemberDropdownOptions}
                            name="typeFilter"
                            value={selectedFilterTeamMemberOptions}
                            onChange={handleTeamMemberChange}
                            placeholder="Types"
                          />
                        </div>
                      </div>
                      <div className="row m-0">
                        <div className="col-lg-6 col-md-6 col-sm-12 p-1">
                          <label htmlFor="">Category</label>
                          {catValue.length === 0 ? (
                            ""
                          ) : (
                            <span
                              style={{
                                position: "absolute",
                                top: "73%",
                                left: "48.5%",
                                zIndex: "1",
                                color: "#fff",
                                background: "red",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "50%",
                              }}
                            >
                              <MdClose onClick={handleCategoryReset} />
                            </span>
                          )}
                          <Select
                            options={categoriesDropdownOptions}
                            name="catFilter"
                            value={selectedFilterCategoriesOptions}
                            onChange={handleCatFilterChange}
                            placeholder="Categories"
                            isMulti
                            isClearable={false}
                          />
                        </div>
                        {catValue.length > 0 && (
                          <div className="col-lg-6 col-md-6 col-sm-12 p-1">
                            <label htmlFor="">Category Exclude</label>
                            <Select
                              options={categoriesDropdownOptions}
                              name="categoryexclude"
                              value={selectedFilterCateogryTypeOptions}
                              onChange={handleCategoryTypeChange}
                              placeholder="categoryexclude"
                              isMulti
                              isClearable={false}
                            />
                          </div>
                        )}
                      </div>
                    </Card>
                  </form>
                </CardHeader>
                <CardBody>
                  <div className="table-box height-adjustment">
                    <table className="table">
                      <thead className="sticky-thead">
                        <tr>
                          <th scope="col" style={{ whiteSpace: "nowrap" }}>
                            Serial No
                          </th>
                          <th>
                            <input
                              type="checkbox"
                              checked={selectedRows.length === data.length}
                              onChange={handleSelectAll}
                            />
                          </th>
                          <th scope="col">URL</th>
                          <th
                            scope="col"
                            onClick={() => sortByClick("domainAuthority")}
                          >
                            <div style={{ display: "flex" }}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "end",
                                  cursor: "pointer",
                                }}
                              >
                                DA
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  cursor: "pointer",
                                }}
                              >
                                <MdOutlineArrowDropUp
                                  size={25}
                                  color={
                                    sortBy == "domainAuthority" &&
                                    sortOrder == "asc"
                                      ? "#0d6efd"
                                      : "#e2e1e1"
                                  }
                                />
                                <MdOutlineArrowDropDown
                                  style={{
                                    marginBottom: "-5px",
                                    marginTop: "-15px",
                                  }}
                                  size={25}
                                  color={
                                    sortBy == "domainAuthority" &&
                                    sortOrder == "dsc"
                                      ? "#0d6efd"
                                      : "#e2e1e1"
                                  }
                                />
                              </div>
                            </div>
                          </th>
                          <th
                            scope="col"
                            onClick={() => sortByClick("domainRating")}
                          >
                            <div style={{ display: "flex" }}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "end",
                                  cursor: "pointer",
                                }}
                              >
                                DR
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  cursor: "pointer",
                                }}
                              >
                                <MdOutlineArrowDropUp
                                  size={25}
                                  color={
                                    sortBy == "domainRating" &&
                                    sortOrder == "asc"
                                      ? "#0d6efd"
                                      : "#e2e1e1"
                                  }
                                />
                                <MdOutlineArrowDropDown
                                  style={{
                                    marginBottom: "-5px",
                                    marginTop: "-15px",
                                  }}
                                  size={25}
                                  color={
                                    sortBy == "domainRating" &&
                                    sortOrder == "dsc"
                                      ? "#0d6efd"
                                      : "#e2e1e1"
                                  }
                                />
                              </div>
                            </div>
                          </th>
                          <th
                            scope="col"
                            onClick={() => sortByClick("authorityScore")}
                          >
                            <div style={{ display: "flex" }}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "end",
                                  cursor: "pointer",
                                }}
                              >
                                AS
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  cursor: "pointer",
                                }}
                              >
                                <MdOutlineArrowDropUp
                                  size={25}
                                  color={
                                    sortBy == "authorityScore" &&
                                    sortOrder == "asc"
                                      ? "#0d6efd"
                                      : "#e2e1e1"
                                  }
                                />
                                <MdOutlineArrowDropDown
                                  style={{
                                    marginBottom: "-5px",
                                    marginTop: "-15px",
                                  }}
                                  size={25}
                                  color={
                                    sortBy == "authorityScore" &&
                                    sortOrder == "dsc"
                                      ? "#0d6efd"
                                      : "#e2e1e1"
                                  }
                                />
                              </div>
                            </div>
                          </th>
                          <th
                            scope="col"
                            onClick={() => sortByClick("spamScore")}
                          >
                            <div style={{ display: "flex" }}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "end",
                                  cursor: "pointer",
                                }}
                              >
                                SS
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  cursor: "pointer",
                                }}
                              >
                                <MdOutlineArrowDropUp
                                  size={25}
                                  color={
                                    sortBy == "spamScore" && sortOrder == "asc"
                                      ? "#0d6efd"
                                      : "#e2e1e1"
                                  }
                                />
                                <MdOutlineArrowDropDown
                                  style={{
                                    marginBottom: "-5px",
                                    marginTop: "-15px",
                                  }}
                                  size={25}
                                  color={
                                    sortBy == "spamScore" && sortOrder == "dsc"
                                      ? "#0d6efd"
                                      : "#e2e1e1"
                                  }
                                />
                              </div>
                            </div>
                          </th>
                          <th
                            style={{ whiteSpace: "nowrap" }}
                            scope="col"
                            onClick={() => sortByClick("ahrefs")}
                          >
                            <div style={{ display: "flex" }}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "end",
                                  cursor: "pointer",
                                }}
                              >
                                Ahrefs Traffic
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  cursor: "pointer",
                                }}
                              >
                                <MdOutlineArrowDropUp
                                  size={25}
                                  color={
                                    sortBy == "ahrefs" && sortOrder == "asc"
                                      ? "#0d6efd"
                                      : "#e2e1e1"
                                  }
                                />
                                <MdOutlineArrowDropDown
                                  style={{
                                    marginBottom: "-5px",
                                    marginTop: "-15px",
                                  }}
                                  size={25}
                                  color={
                                    sortBy == "ahrefs" && sortOrder == "dsc"
                                      ? "#0d6efd"
                                      : "#e2e1e1"
                                  }
                                />
                              </div>
                            </div>
                          </th>
                          <th
                            style={{ whiteSpace: "nowrap" }}
                            scope="col"
                            onClick={() => sortByClick("semrush")}
                          >
                            <div style={{ display: "flex" }}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "end",
                                  cursor: "pointer",
                                }}
                              >
                                Semrush Traffic
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  cursor: "pointer",
                                }}
                              >
                                <MdOutlineArrowDropUp
                                  size={25}
                                  color={
                                    sortBy == "semrush" && sortOrder == "asc"
                                      ? "#0d6efd"
                                      : "#e2e1e1"
                                  }
                                />
                                <MdOutlineArrowDropDown
                                  style={{
                                    marginBottom: "-5px",
                                    marginTop: "-15px",
                                  }}
                                  size={25}
                                  color={
                                    sortBy == "semrush" && sortOrder == "dsc"
                                      ? "#0d6efd"
                                      : "#e2e1e1"
                                  }
                                />
                              </div>
                            </div>
                          </th>
                          <th
                            style={{ whiteSpace: "nowrap" }}
                            scope="col"
                            onClick={() => sortByClick("priceGP")}
                          >
                            <div style={{ display: "flex" }}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "end",
                                  cursor: "pointer",
                                }}
                              >
                                GP Price
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  cursor: "pointer",
                                }}
                              >
                                <MdOutlineArrowDropUp
                                  size={25}
                                  color={
                                    sortBy == "priceGP" && sortOrder == "asc"
                                      ? "#0d6efd"
                                      : "#e2e1e1"
                                  }
                                />
                                <MdOutlineArrowDropDown
                                  style={{
                                    marginBottom: "-5px",
                                    marginTop: "-15px",
                                  }}
                                  size={25}
                                  color={
                                    sortBy == "priceGP" && sortOrder == "dsc"
                                      ? "#0d6efd"
                                      : "#e2e1e1"
                                  }
                                />
                              </div>
                            </div>
                          </th>
                          <th
                            style={{ whiteSpace: "nowrap" }}
                            scope="col"
                            onClick={() => sortByClick("priceLI")}
                          >
                            <div style={{ display: "flex" }}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "end",
                                  cursor: "pointer",
                                }}
                              >
                                LI Price
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  cursor: "pointer",
                                }}
                              >
                                <MdOutlineArrowDropUp
                                  size={25}
                                  color={
                                    sortBy == "priceLI" && sortOrder == "asc"
                                      ? "#0d6efd"
                                      : "#e2e1e1"
                                  }
                                />
                                <MdOutlineArrowDropDown
                                  style={{
                                    marginBottom: "-5px",
                                    marginTop: "-15px",
                                  }}
                                  size={25}
                                  color={
                                    sortBy == "priceLI" && sortOrder == "dsc"
                                      ? "#0d6efd"
                                      : "#e2e1e1"
                                  }
                                />
                              </div>
                            </div>
                          </th>
                          <th>Country</th>
                          <th scope="col">Categories</th>
                          <th>Types</th>
                          <th style={{ whiteSpace: "nowrap" }} scope="col">
                            Link Status
                          </th>
                          <th style={{ whiteSpace: "nowrap" }}>
                            Contact Email
                          </th>
                          <th style={{ whiteSpace: "nowrap" }}>Email Used</th>
                          <th style={{ whiteSpace: "nowrap" }}>Team Member</th>
                          <th style={{ whiteSpace: "nowrap" }}>Language</th>
                          <th style={{ whiteSpace: "nowrap" }}>
                            Slack Community
                          </th>
                          {checkPermissions("Admin") ||
                          checkPermissions("Editor") ? (
                            <th scope="col">Actions</th>
                          ) : null}
                        </tr>
                      </thead>
                      <tbody className="scrollable-tbody">
                        {data.length > 0 ? (
                          <>
                            {data?.map((user, index) => (
                              <React.Fragment key={user?.websites[0]._id}>
                                <tr>
                                  <td>
                                    {(currentPage - 1) * entries + index + 1}
                                  </td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      checked={selectedRows.includes(
                                        user.websites[0]._id
                                      )}
                                      onChange={() =>
                                        handleSelectRow(user.websites[0]._id)
                                      }
                                    />
                                  </td>
                                  <td>
                                    <div className="d-flex align-items-center mx-2">
                                      <a
                                        href={webCheck(user?.websites[0].url)}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {highlightSearchValue(
                                          removeHttp(user?.websites[0].url)
                                        )}
                                      </a>
                                      {user?.websites?.length > 1 ? (
                                        <Button
                                          size="sm"
                                          className="btn btn-sm btn-success mx-1 my-1"
                                          onClick={() =>
                                            toggleRowExpansion(user?._id)
                                          }
                                        >
                                          {expandedRow === user?._id
                                            ? user?.websites.length - 1
                                            : user?.websites.length - 1}
                                        </Button>
                                      ) : null}
                                    </div>
                                  </td>
                                  <td>
                                    {highlightSearchValue(
                                      user?.websites[0].domainAuthority
                                    )}
                                  </td>
                                  <td>
                                    {highlightSearchValue(
                                      user?.websites[0].domainRating
                                    )}
                                  </td>
                                  <td>
                                    {highlightSearchValue(
                                      user?.websites[0].authorityScore
                                    )}
                                  </td>
                                  <td>
                                    {highlightSearchValue(
                                      user?.websites[0].spamScore
                                    )}
                                  </td>
                                  <td>
                                    {convertToStringWithSuffix(
                                      user?.websites[0].ahrefs
                                    )}
                                  </td>
                                  <td>
                                    {convertToStringWithSuffix(
                                      user?.websites[0].semrush
                                    )}
                                  </td>
                                  <td>
                                    {highlightSearchValue(
                                      user?.websites[0].currencyGP
                                    ) === "0"
                                      ? ""
                                      : highlightSearchValue(
                                          user?.websites[0].currencyGP
                                        )}{" "}
                                    {highlightSearchValue(
                                      user?.websites[0].priceGP
                                    )}
                                  </td>
                                  <td>
                                    {highlightSearchValue(
                                      user?.websites[0].currencyGP
                                    ) === "0"
                                      ? ""
                                      : highlightSearchValue(
                                          user?.websites[0].currencyGP
                                        )}{" "}
                                    {highlightSearchValue(
                                      user?.websites[0].priceLI
                                    )}
                                  </td>
                                  <td>
                                    {highlightSearchValue(
                                      user?.websites[0].country
                                    )}
                                  </td>
                                  <td>
                                    {user?.websites[0]?.categories?.map(
                                      (category, index) => (
                                        <React.Fragment key={category?._id}>
                                          <span
                                            style={{
                                              display: "block",
                                              background: "#4CAF50",
                                              color: "#fff",
                                              fontWeight: "bold",
                                              fontSize: "12px",
                                              padding: "3px",
                                              boxSizing: "border-box",
                                              borderRadius: "5px",
                                              whiteSpace: "nowrap",
                                              textAlign: "center",
                                              marginBottom:
                                                index !==
                                                user.websites[0].categories
                                                  .length -
                                                  1
                                                  ? "5px"
                                                  : "0",
                                            }}
                                          >
                                            {highlightSearchValue(
                                              category?.categoryName
                                            )}
                                          </span>
                                        </React.Fragment>
                                      )
                                    )}
                                  </td>
                                  <td>
                                    {user?.websites[0]?.types?.map(
                                      (type, index) => (
                                        <React.Fragment key={type?._id}>
                                          <span
                                            style={{
                                              display: "block",
                                              background: "#4CAF50",
                                              color: "#fff",
                                              fontWeight: "bold",
                                              fontSize: "12px",
                                              padding: "3px",
                                              boxSizing: "border-box",
                                              borderRadius: "5px",
                                              whiteSpace: "nowrap",
                                              textAlign: "center",
                                              marginBottom: "5px",
                                            }}
                                          >
                                            {highlightSearchValue(
                                              type?.TypeName
                                            )}
                                          </span>
                                        </React.Fragment>
                                      )
                                    )}
                                  </td>
                                  <td>
                                    {highlightSearchValue(
                                      user?.websites[0].linkStatus
                                    )}
                                  </td>
                                  <td>
                                    {highlightSearchValue(
                                      user?.websites[0].contactEmail
                                    )}
                                  </td>
                                  <td>
                                    {highlightSearchValue(
                                      user?.websites[0].emailUsed
                                    )}
                                  </td>
                                  <td>
                                    {highlightSearchValue(
                                      user?.websites[0].teamMember[0]?.name
                                    )}
                                  </td>
                                  <td>
                                    {highlightSearchValue(
                                      user?.websites[0].language
                                    )}
                                  </td>
                                  <td>
                                    {highlightSearchValue(
                                      user?.websites[0].slackCommunity
                                    )}
                                  </td>
                                  <td>
                                    <div className="d-flex">
                                      {(checkPermissions("Admin") ||
                                        (checkPermissions("Editor") &&
                                          userId ===
                                            user.websites[0].createdBy
                                              ?._id)) && (
                                        <>
                                          <button
                                            onClick={() => handleUpdate(user)}
                                            className="btn btn-sm btn-primary my-1 mx-2"
                                          >
                                            <BsPencilSquare />
                                          </button>
                                          <button
                                            onClick={() =>
                                              handleDelete([
                                                user.websites[0]._id,
                                              ])
                                            }
                                            className="btn btn-sm btn-danger my-1"
                                          >
                                            <BsTrash />
                                          </button>
                                        </>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                                {expandedRow === user?._id &&
                                  user?.websites.map((website, webIndex) =>
                                    webIndex !== 0 ? (
                                      <tr key={website._id}>
                                        <td style={{ background: "#c0c0c0" }}>
                                          {webIndex}
                                        </td>
                                        <td style={{ background: "#c0c0c0" }}>
                                          <input
                                            type="checkbox"
                                            checked={selectedRows.includes(
                                              website._id
                                            )}
                                            onChange={() =>
                                              handleSelectRow(website._id)
                                            }
                                          />
                                        </td>
                                        <td style={{ background: "#c0c0c0" }}>
                                          <a
                                            href={website.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            {highlightSearchValue(
                                              removeHttp(website.url)
                                            )}
                                          </a>
                                        </td>
                                        <td style={{ background: "#c0c0c0" }}>
                                          {highlightSearchValue(
                                            website.domainAuthority
                                          )}
                                        </td>
                                        <td style={{ background: "#c0c0c0" }}>
                                          {highlightSearchValue(
                                            website.domainRating
                                          )}
                                        </td>
                                        <td style={{ background: "#c0c0c0" }}>
                                          {highlightSearchValue(
                                            website.authorityScore
                                          )}
                                        </td>
                                        <td style={{ background: "#c0c0c0" }}>
                                          {highlightSearchValue(
                                            website.spamScore
                                          )}
                                        </td>
                                        <td style={{ background: "#c0c0c0" }}>
                                          {convertToStringWithSuffix(
                                            website.ahrefs
                                          )}
                                        </td>
                                        <td style={{ background: "#c0c0c0" }}>
                                          {convertToStringWithSuffix(
                                            website.semrush
                                          )}
                                        </td>
                                        <td style={{ background: "#c0c0c0" }}>
                                          {highlightSearchValue(
                                            website.currencyGP
                                          ) === "0"
                                            ? ""
                                            : highlightSearchValue(
                                                website.currencyGP
                                              )}{" "}
                                          {highlightSearchValue(
                                            website.priceGP
                                          )}
                                        </td>
                                        <td style={{ background: "#c0c0c0" }}>
                                          {highlightSearchValue(
                                            website.currencyGP
                                          ) === "0"
                                            ? ""
                                            : highlightSearchValue(
                                                website.currencyGP
                                              )}{" "}
                                          {highlightSearchValue(
                                            website.priceLI
                                          )}
                                        </td>
                                        <td style={{ background: "#c0c0c0" }}>
                                          {highlightSearchValue(
                                            website.country
                                          )}
                                        </td>
                                        <td style={{ background: "#c0c0c0" }}>
                                          {website?.categories?.map(
                                            (category, index) => (
                                              <React.Fragment
                                                key={category?._id}
                                              >
                                                <span
                                                  style={{
                                                    display: "block",
                                                    background: "#4CAF50",
                                                    color: "#fff",
                                                    fontWeight: "bold",
                                                    fontSize: "12px",
                                                    padding: "3px",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    whiteSpace: "nowrap",
                                                    textAlign: "center",
                                                    marginBottom:
                                                      index !==
                                                      website.categories
                                                        .length -
                                                        1
                                                        ? "5px"
                                                        : "0",
                                                  }}
                                                >
                                                  {highlightSearchValue(
                                                    category?.categoryName
                                                  )}
                                                </span>
                                              </React.Fragment>
                                            )
                                          )}
                                        </td>
                                        <td style={{ background: "#c0c0c0" }}>
                                          {website?.types?.map(
                                            (type, index) => (
                                              <React.Fragment key={type?._id}>
                                                <span
                                                  style={{
                                                    display: "block",
                                                    background: "#4CAF50",
                                                    color: "#fff",
                                                    fontWeight: "bold",
                                                    fontSize: "12px",
                                                    padding: "3px",
                                                    boxSizing: "border-box",
                                                    borderRadius: "5px",
                                                    whiteSpace: "nowrap",
                                                    textAlign: "center",
                                                    marginBottom: "5px",
                                                  }}
                                                >
                                                  {highlightSearchValue(
                                                    type?.TypeName
                                                  )}
                                                </span>
                                              </React.Fragment>
                                            )
                                          )}
                                        </td>
                                        <td style={{ background: "#c0c0c0" }}>
                                          {highlightSearchValue(
                                            website.linkStatus
                                          )}
                                        </td>
                                        <td style={{ background: "#c0c0c0" }}>
                                          {highlightSearchValue(
                                            website.contactEmail
                                          )}
                                        </td>
                                        <td style={{ background: "#c0c0c0" }}>
                                          {highlightSearchValue(
                                            website.emailUsed
                                          )}
                                        </td>
                                        <td style={{ background: "#c0c0c0" }}>
                                          {highlightSearchValue(
                                            website.teamMember[0]?.name
                                          )}
                                        </td>
                                        <td style={{ background: "#c0c0c0" }}>
                                          {highlightSearchValue(
                                            website.language
                                          )}
                                        </td>
                                        <td style={{ background: "#c0c0c0" }}>
                                          {highlightSearchValue(
                                            website.slackCommunity
                                          )}
                                        </td>
                                        <td style={{ background: "#c0c0c0" }}>
                                          <div className="d-flex">
                                            {(checkPermissions("Admin") ||
                                              (checkPermissions("Editor") &&
                                                userId ===
                                                  website.createdBy?._id)) && (
                                              <>
                                                <button
                                                  onClick={() =>
                                                    handleUpdateRow(website)
                                                  }
                                                  className="btn btn-sm btn-primary my-1 mx-2"
                                                >
                                                  <BsPencilSquare />
                                                </button>
                                                <button
                                                  onClick={() =>
                                                    handleDelete([website._id])
                                                  }
                                                  className="btn btn-sm btn-danger my-1"
                                                >
                                                  <BsTrash />
                                                </button>
                                              </>
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                    ) : null
                                  )}
                              </React.Fragment>
                            ))}
                          </>
                        ) : (
                          <tr>
                            <th colSpan={20} className="text-center">
                              No Data Found
                            </th>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </CardBody>

                <CardFooter>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Pagination
                      currentPage={currentPage}
                      data={{ per_page: entries, total: paginationValue.total }}
                      lastPage={incrementToNextStep(
                        paginationValue.total / entries
                      )}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </CardFooter>
              </Card>
            </div>
            <Modal show={showDeleteModal} onHide={handleCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>Delete User</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Are you sure you want to delete this user?
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                  Cancel
                </Button>
                <Button variant="danger" onClick={handleDeleteSubmit}>
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={showDownloadModal} onHide={handleDownloadCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>Download Sample</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                    <label htmlFor="from" className="form-label">
                      From
                    </label>
                    <input
                      className="form-control"
                      type="number"
                      min={1}
                      max={entries.total}
                      placeholder="From"
                      value={from}
                      onChange={(e) => setFromValue(e.target.value)}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                    <label htmlFor="to" className="form-label">
                      To
                    </label>
                    <input
                      className="form-control"
                      type="number"
                      min={1}
                      max={entries.total}
                      placeholder="To"
                      value={to}
                      onChange={(e) => setToValue(e.target.value)}
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleDownloadCloseModal}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  href={`${process.env.REACT_APP_SERVER_API}/api/web/download?sortOrder=${sortOrder}&sortBy=${sortBy == null ? '' : sortBy}&from=${from}&to=${to}&searchValue=${searchValue}&domainRatingStart=${domainRatingStart}&domainRatingEnd=${domainRatingEnd}&domainAuthorityStart=${domainAuthorityStart}&domainAuthorityEnd=${domainAuthorityEnd}&spamScoreStart=${spamScoreStart}&spamScoreEnd=${spamScoreEnd}&priceStart=${priceStart}&priceEnd=${priceEnd}&authorityScoreStart=${authorityScoreStart}&authorityScoreEnd=${authorityScoreEnd}&ahrefsStart=${ahrefsStart}&ahrefsEnd=${ahrefsEnd}&semrushStart=${semrushStart}&semrushEnd=${semrushEnd}&linkStatus=${linkStatusSelectedOptions.value}&teamMember=${teamMemberValue}&typeId=${typeValue}&categoryId=${catValue.join(",")}&country=${countryValue}&language=${languageValue}&categoryType=${categoryValue.join(",")}`}
                >
                  Download
                </Button>
              </Modal.Footer>
            </Modal>

            {/* <Modal show={showModal} onClick={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Website Details</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{overflow:'auto'}}>
                <table className="table">
                  <thead className="sticky-thead">
                    <tr>
                      <th scope="col">URL</th>
                      <th scope="col">Domain Authority</th>
                      <th scope="col">Domain Rating</th>
                      <th scope="col">Authority Score</th>
                      <th scope="col">Spam Score</th>
                      <th scope="col">Ahrefs Traffic</th>
                      <th scope="col">Semrush Traffic</th>
                      <th scope="col">GP Price</th>
                      <th scope="col">LI Price</th>
                      <th scope="col">Country</th>
                      <th scope="col">Categories</th>
                      <th scope="col">Types</th>
                      <th scope="col">Link Status</th>
                      <th scope="col">Contact Email</th>
                      <th scope="col">Email Used</th>
                      <th scope="col">Team Member</th>
                      <th scope="col">Slack Community</th>
                    </tr>
                  </thead>
                  <tbody className="scrollable-tbody">
                    {data.length > 0 ? (
                      <>
                        {data.map((user) => (
                          user.websites.map((website) => (
                            <tr key={website._id}>
                              <td>
                                <a
                                  href={website.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {highlightSearchValue(removeHttp(website.url))}
                                </a>
                              </td>
                              <td>{highlightSearchValue(website.domainAuthority)}</td>
                              <td>{highlightSearchValue(website.domainRating)}</td>
                              <td>{website.authorityScore}</td>
                              <td>{highlightSearchValue(website.spamScore)}</td>
                              <td>{convertToStringWithSuffix(website.ahrefs)}</td>
                              <td>{convertToStringWithSuffix(website.semrush)}</td>
                              <td>{highlightSearchValue(website.currencyGP) === "0" ? "" : highlightSearchValue(website.currencyGP)} {highlightSearchValue(website.priceGP)}</td>
                              <td>{highlightSearchValue(website.currencyLI) === "0" ? "" : highlightSearchValue(website.currencyLI)} {highlightSearchValue(website.priceLI)}</td>
                              <td>{highlightSearchValue(website.country)}</td>
                              <td>
                                {website.categories?.map((category, index) => (
                                  <span
                                    key={category?._id}
                                    style={{
                                      display: "block",
                                      background: "#4CAF50",
                                      color: "#fff",
                                      fontWeight: "bold",
                                      fontSize: "12px",
                                      padding: "3px",
                                      boxSizing: "border-box",
                                      borderRadius: "5px",
                                      whiteSpace: "nowrap",
                                      textAlign: "center",
                                      marginBottom:
                                        index !== website.categories.length - 1
                                          ? "5px"
                                          : "0",
                                    }}
                                  >
                                    {highlightSearchValue(category?.categoryName)}
                                  </span>
                                ))}
                              </td>
                              <td>
                                {website.types?.map((type, index) => (
                                  <span
                                    key={type?._id}
                                    style={{
                                      display: "block",
                                      background: "#4CAF50",
                                      color: "#fff",
                                      fontWeight: "bold",
                                      fontSize: "12px",
                                      padding: "3px",
                                      boxSizing: "border-box",
                                      borderRadius: "5px",
                                      whiteSpace: "nowrap",
                                      textAlign: "center",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    {highlightSearchValue(type?.TypeName)}
                                    &nbsp;
                                  </span>
                                ))}
                              </td>
                              <td>{highlightSearchValue(website.linkStatus)}</td>
                              <td>{highlightSearchValue(website.contactEmail)}</td>
                              <td>{highlightSearchValue(website.emailUsed)}</td>
                              <td>{highlightSearchValue(website.teamMember[0].name)}</td>
                              <td>{highlightSearchValue(website.slackCommunity)}</td>
                            </tr>
                          ))
                        ))}
                      </>
                    ) : (
                      <tr>
                        <th colSpan={20} className="text-center">
                          No Data Found
                        </th>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal> */}

            <Footer />
          </div>
        </>
      </div>
    </div>
  );
}

export default Websites;
